import React from "react";

export default function DownloadIcon({ ...rest }) {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        {...rest}
      >
        <path
          d="M18.2498 20.5C18.664 20.4999 19 20.8356 19 21.2498C19 21.664 18.6644 21.9999 18.2502 22L5.25022 22.0038C4.836 22.0039 4.5 21.6682 4.5 21.254C4.5 20.8398 4.83557 20.5039 5.24978 20.5038L18.2498 20.5ZM11.6482 2.01179L11.75 2.00494C12.1297 2.00494 12.4435 2.2871 12.4932 2.65317L12.5 2.75494L12.499 16.4399L16.2208 12.7196C16.4871 12.4534 16.9038 12.4292 17.1974 12.6471L17.2815 12.7197C17.5477 12.986 17.5719 13.4027 17.354 13.6963L17.2814 13.7804L12.2837 18.777C12.0176 19.0431 11.6012 19.0674 11.3076 18.8498L11.2235 18.7773L6.22003 13.7807C5.92694 13.488 5.92661 13.0131 6.21931 12.72C6.48539 12.4536 6.90204 12.4291 7.1958 12.6467L7.27997 12.7193L10.999 16.4329L11 2.75494C11 2.37525 11.2822 2.06145 11.6482 2.01179L11.75 2.00494L11.6482 2.01179Z"
          fill="#E2136E"
        />
      </svg>
    </div>
  );
}
