import React, { useEffect, useState } from "react";
import rocket from "./img/rocket.svg";
const MoveToTop = (showBelow) => {
  const [show, setShow] = useState(showBelow ? false : true);
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 100);
    });
  }, [scroll]);
  const handleScroll = () => {
    if (window.pageYOffset > showBelow) {
      if (!show) setShow(true);
    } else {
      if (show) setShow(false);
    }
  };
  useEffect(() => {
    if (showBelow) {
      window.addEventListener(`scroll`, handleScroll);
      return () => window.removeEventListener(`scroll`, handleScroll);
    }
  });
  const handleClick = () => {
    window[`scrollTo`]({ top: 0, behavior: `smooth` });
  };
  return (
    <>
      <div className={scroll ? "scroll-area moveToTop" : "scroll-area"}>
        <a onClick={handleClick} href="#/">
          <img src={rocket} alt="" />
        </a>
      </div>
    </>
  );
};

export default MoveToTop;
