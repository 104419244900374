// import { Viewer } from "@react-pdf-viewer/core";
// import "@react-pdf-viewer/core/lib/styles/index.css";
import React, { useState } from "react";
import CustomModal from "../../Dashboard/Components/Layout/CustomModal";
import BackPage from "../../Dashboard/Components/Others/BackPage";
import EventDetailsCard from "../../Dashboard/Components/Others/EventDetailsCard";
// import ImageHeader from "../../Dashboard/Components/Others/ImageHeader";
import ImageHeader from "../../Dashboard/Components/Others/ImageHeader";
import MobileFooter from "../../Dashboard/Components/Others/MobileFooter";
import ClearIcon from "../../assets/image/close-icon.png";
import LocationIcon from "../../assets/image/others/LocationIcon";
import BkashIcon from "../../assets/image/others/bkash-logo.png";
import DownloadLocation from "../../assets/image/others/location.png";
import BG from "../../assets/image/others/park.jpeg";
import useCustomFavicon from "../../hooks/useCustomFavicon";
import { TabTitle } from "../../utils/GeneralFunctions";
import MobileContainer from "./MobileContainer";

export default function LocationPage() {
  TabTitle("Location Map");
  useCustomFavicon(BkashIcon);
  const [showPDF, setShowPDF] = useState(false);

  const toggle = () => {
    setShowPDF(false);
  };

  return (
    <MobileContainer>
      {showPDF ? (
        <CustomModal
          open={true}
          toggle={toggle}
          size={"sm"}
          modalBody="contact-modal"
        >
          <div className="d-flex justify-content-end mb-2 position-relative">
            <button
              className="btn p-2 d-flex justify-content-center align-items-center"
              style={{ borderRadius: "50%", width: 30, height: 30 }}
              onClick={toggle}
            >
              <img style={{ height: 12 }} src={ClearIcon} alt="" />
            </button>
          </div>

          <div>
            <img
              className="w-100"
              style={{ objectFit: "cover" }}
              src={DownloadLocation}
              alt=""
            />
          </div>
          {/* <div className="contact-modal-pdf">
            <ViewPDF pdfFile={TEST_PDF} toggle={toggle} />
          </div> */}
        </CustomModal>
      ) : null}

      <BackPage
        title="Location Map"
        backLink={`/commercial-meet-2024`}
        downloadDocument={DownloadLocation}
        ext="png"
      />
      <ImageHeader bg={BG} />
      <EventDetailsCard
        title="Venu Details"
        icon={LocationIcon}
        handleCardClick={() => setShowPDF(true)}
      />
      <div className="position-absolute bottom-0 w-100">
        <MobileFooter />
      </div>
    </MobileContainer>
  );
}
