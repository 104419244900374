import axios from "axios";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { useLocation } from "react-router-dom";
import CustomInput from "../../components/Common/InputComponent/CustomInput";
import { demoRequestSchema } from "../../Schemas/DemoFormSchema";
import logoIcon from "./img/icon-svg.svg";
import popupImage from "./img/popup.png";
const ContactForm = () => {
  const data = useSelector((state) => state.demoReqFormData);
  console.log(data);
  let location = useLocation();
  const searchSubject = new URLSearchParams(location.search).get("coursename");

  const initialValues = {
    name: "",
    subject: searchSubject,
    phoneNumber: "",
    officeEmail: "",
    position: "",
    address: "",
  };

  const [values, setValues] = useState(null);
  const [open, setOpen] = useState(false);
  const onCloseModal = () => setOpen(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  setTimeout(() => {
    localStorage.removeItem("datatitle");
  }, 5000);
  const handlesubmit = (values) => {
    // dispatch(storeData(values));
    setLoading(true);
    axios
      .post(
        "https://maacsolutionsbackend-api.salesx-staging.xyz/api/v1/users/demo-user-create",
        values
      )
      .then(function (response) {
        setSuccessMessage(response.status);
        setLoading(false);
        setOpen(true);
      })
      .catch(function (error) {
        console.log(error.message);
        setErrorMessage(error?.response?.data?.message);
        setLoading(false);
        setOpen(true);
      });
    setValues("");
  };

  // const xeleratorFieldAdd = initialValues?.subject;
  // console.log(xeleratorFieldAdd);
  // const [xeleratorOption, setXeleratorOption]=useState(xeleratorFieldAdd)
  return (
    <div className="conatct-form-wrap demo-request-main-area-wrap">
      <Modal open={open} onClose={onCloseModal} center>
        <div className="send-message-popup p-5">
          <h6>
            {errorMessage ? (
              <>
                <img
                  style={{ height: "100px" }}
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/f0/Error.svg/1200px-Error.svg.png"
                  alt=""
                />
                <p>{errorMessage}</p>
              </>
            ) : (
              <>
                {" "}
                <img src={popupImage} alt="" />{" "}
                <p>
                  Thanks for being in touch with us. We will connect you
                  shortly.
                </p>
              </>
            )}
          </h6>
        </div>
      </Modal>
      <div className="container">
        <div className="contact-form-inner-wrap">
          <div className="row">
            <div className="col-lg-6 mb-5">
              <div className="contact-form-left">
                <span></span>
                <h6>One step at a time,</h6>
                <h2>Light your next venture with us</h2>
                <p>We are forever here to hear you out. </p>
                <Formik
                  initialValues={values || initialValues}
                  validationSchema={demoRequestSchema}
                  onSubmit={handlesubmit}
                  enableReinitialize={true}
                >
                  {({ values, errors, isSubmitting }) => (
                    <Form className="from-group mt-4">
                      {/* {console.log('values', values, errors)} */}
                      <Row>
                        <Col md="12 mb-2">
                          <Field
                            name="name"
                            label={"Name"}
                            className="from-group"
                            type="text"
                            component={CustomInput}
                            placeholder="Enter Name"
                          />
                        </Col>{" "}
                        <Col md="12 mb-2">
                          <Field
                            name="subject"
                            label={"Subject"}
                            type="text"
                            disabled
                            component={CustomInput}
                            value={searchSubject}
                            placeholder="Enter Name"
                          />
                        </Col>
                        {/* {xeleratorFieldAdd === "Xelerator" ? (
                          <Col md="12 mb-2">
                            <label htmlFor="Subject Xelerator">
                              Subject Xelerator
                            </label>
                            <Field
                              as="select"
                              name="subject"
                              className="xelerator-select-options"
                            >
                              <option value="red">Red</option>
                              <option value="green">Green</option>
                              <option value="blue">Blue</option>
                            </Field>
                          </Col>
                        ) : null} */}
                        <Col md="12 mb-2" style={{ position: "relative" }}>
                          <div className="phone-number-input"></div>
                          <Field
                            name="phoneNumber"
                            label={"Phone number"}
                            type="text"
                            className="phone-number-input-wrap"
                            component={CustomInput}
                            placeholder="Enter your phone number"
                          />
                        </Col>{" "}
                        <Col md="12 mb-2">
                          <Field
                            name="officeEmail"
                            label={"Office email"}
                            type="email"
                            component={CustomInput}
                            placeholder="Enter your office email"
                          />
                        </Col>
                        <Col md="12 mb-2">
                          <Field
                            name="position"
                            label={"Position"}
                            type="text"
                            component={CustomInput}
                            placeholder="Enter your position"
                          />
                        </Col>{" "}
                        <Col md="12 mb-2">
                          <Field
                            name="address"
                            label={"Office address"}
                            type="textarea"
                            component={CustomInput}
                            placeholder="Enter your position"
                          />
                        </Col>
                      </Row>

                      <div className="mt-3">
                        <button
                          className="form-submit-btn demo-request-submit-btn"
                          type="submit"
                        >
                          {loading ? (
                            <>
                              <div
                                class="spinner-border text-success"
                                role="status"
                              >
                                <span class="visually-hidden">Loading...</span>
                              </div>
                            </>
                          ) : (
                            "Submit"
                          )}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="contact-form-right">
                <img src={logoIcon} alt="" />
                <span className="border-top-right-area"></span>
                <div className="contact-form-right-inner">
                  <div className="contact-form-right-from-group">
                    <h6>Location</h6>
                    <h3>House 15, Road - 24, Gulshan 2 Dhaka - 1212</h3>
                  </div>
                  <div className="contact-form-right-from-group">
                    <h6>Working Hour</h6>
                    <h3>Sunday To Thursday</h3>
                    <h3>9:00 AM to 8:00 PM </h3>
                    <p>Our Support Team is available 24Hrs</p>
                  </div>
                  <div className="contact-form-right-from-group">
                    <h6>Contact Us</h6>
                    <h3>+88 01713254910</h3>
                    <p>info@maacsolutions.com</p>
                    <div className="social-link">
                      <a
                        href="https://www.facebook.com/MAAC.Bangladesh"
                        target="blank"
                      >
                        <i className="fab fa-facebook"></i>
                      </a>
                      <a
                        href="https://twitter.com/MAAC_Solutions"
                        target="blank"
                      >
                        <i className="fab fa-twitter"></i>
                      </a>
                      {/* 
                  <a href="!#" target="blank">
                    <i className="fab fa-instagram"></i>
                  </a> */}
                      <a
                        href="https://www.linkedin.com/company/market-access-analytics-and-consulting-maac"
                        target="blank"
                      >
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </div>
                  </div>
                </div>

                <span className="border-left-bottom-area"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
