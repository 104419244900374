import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { Col, Dropdown, DropdownMenu, DropdownToggle, Row } from "reactstrap";
import SimpleBar from "simplebar-react";
import bulletPoint from "../../../assests/images/Ellipse 2.svg";
//Import images
import moment from "moment";

const NotificationDropdown = (props) => {
  const [menu, setMenu] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { notificationData, authtoken } = useSelector((state) => ({
    authtoken: state.Login.token,
    // notificationData: state.DashboardReducer.notificationData,
  }));

  let count = 0;

  return (
    <>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon waves-effect"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="bx bx-bell bx-tada" />
          <span className="badge badge-danger badge-pill">{count}</span>
        </DropdownToggle>

        <DropdownMenu
          className="dropdown-menu dropdown-menu-lg p-0 notification-dropdown"
          right
          style={{ width: "400px" }}
        >
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {"Notifications"} </h6>
              </Col>
              <div className="col-auto">
                <a href="#!" className="small">
                  View All
                </a>
              </div>
            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>
            {(notificationData?.data?.data || []).map((data, key) => {
              return (
                <>
                  <div
                    className={
                      data?.seen !== true ? "notification-inner-area" : ""
                    }
                  >
                    <div
                      className="media notification-info"
                      // onClick={() => handleNotification(data)}
                    >
                      <div className="avatar-xs me-3">
                        <span
                          className="avatar-title rounded-circle font-size-16"
                          style={{ background: "#E5ECF2" }}
                        >
                          <i
                            className="bx bx-bell"
                            style={{ color: "black" }}
                          />
                        </span>
                      </div>
                      {data?.seen !== true ? (
                        <img
                          src={bulletPoint}
                          alt="icon"
                          className="bullet-icon"
                        />
                      ) : (
                        <span></span>
                      )}

                      <div className="media-body">
                        <h6 className="mt-0 mb-3">{data?.description}</h6>
                        <p>{moment(data?.createdAt).format("MMMM Do YYYY")}</p>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </SimpleBar>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default NotificationDropdown;
