import React from "react";
import { Col } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { Row } from "reactstrap";
import CardComponent from "../../../../components/Common/CardComponent";
import { TabTitle } from "../../../../utils/GeneralFunctions";
import EsQFooter from "./EsQFooter";
import LafargeHeader from "./LafargeHeader";
import successImage from "./img/successIcon.png";
const FeedbackSuccess = () => {
  const path = useLocation();
  const checkPath = path.pathname;
  TabTitle("MAAC Success");
  return (
    <div>
      {/* {

checkPath=== "/newzealand-dairy/success"
      } */}

      <LafargeHeader checkPath={checkPath} />
      <div className="lafarge-form-main-wrap">
        <Row className="">
          <Col md="7" className="mx-auto ">
            <CardComponent className={"lafarge-inner-form-wrap"}>
              <Col md="6" className="offset-md-3">
                <div className="lafarge-success-inner-wrap">
                  <img src={successImage} alt="" />
                  <h3>Successfully Submitted</h3>
                  <p>Thank you for your feedback.</p>
                  <Link to="/" className="btn lafarge-first-stap-button">
                    Explore
                  </Link>
                </div>
              </Col>
            </CardComponent>
          </Col>
        </Row>
      </div>
      <EsQFooter />
    </div>
  );
};

export default FeedbackSuccess;
