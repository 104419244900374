import * as Yup from "yup";

const countWords = (str) => {
  return str.trim().split(/\s+/).length;
};

const createSectionSchema = Yup.object({
  title: Yup.string().required("This field is required"),
  file: Yup.string().when("imageUrl", {
    is: (val) => !val,
    then: Yup.string().required("This field is required"),
  }),
  details: Yup.string().test(
    "word-count",
    "This field cannot be more than 80 words.",
    (value) => {
      return countWords(value) <= 80;
    }
  ),
  imageUrl: Yup.string(),
});

export { createSectionSchema };
