import {
  ADD_HERO_LOADED,
  DELETE_HERO_LOADED,
  GET_HERO,
  GET_HERO_ERROR,
  GET_HERO_LOADED,
} from "./actionTypes";

// get hero loaded
const getHeroLoaded = ({ payload }) => {
  return {
    type: GET_HERO_LOADED,
    payload,
  };
};

// get all hero
const getAllHero = (data) => {
  return {
    type: GET_HERO,
    payload: data,
  };
};

// get hero error
const getHeroFail = (err) => {
  return {
    type: GET_HERO_ERROR,
    payload: err,
  };
};

// add hero loaded
const addHeroLoad = (data) => {
  return {
    type: ADD_HERO_LOADED,
    payload: data,
  };
};

// delete hero loaded
const deleteHeroLoad = (data) => {
  return {
    type: DELETE_HERO_LOADED,
    payload: data,
  };
};

export { getHeroLoaded, getAllHero, getHeroFail, addHeroLoad, deleteHeroLoad };
