import { all, fork } from "redux-saga/effects";
import categoriesSaga from "../Redux/Centro/Category/saga";
import heroSaga from "../Redux/Centro/Hero/saga";
import productsSaga from "../Redux/Centro/Product/saga";
import DashboardSaga from "./Dashboard/saga";
import RaffleSaga from "./Raffle/saga";
import requestListSaga from "./downloadExcel/saga";
import LayoutSaga from "./layout/saga";
import LoginSaga from "./login/saga";

export default function* rootSaga() {
  yield all([fork(LoginSaga)]);
  yield all([fork(DashboardSaga)]);
  yield all([fork(LayoutSaga)]);
  yield all([fork(RaffleSaga)]);
  yield all([fork(categoriesSaga)]);
  yield all([fork(productsSaga)]);
  yield all([fork(heroSaga)]);
  yield all([fork(requestListSaga)]);
}
