import React from "react";
import { Container } from "react-bootstrap";
import BreadcrumbDashboard from "../../../Breadcrumb/BreadcrumbDashboard";

export default function CentroDashboard() {
  return (
    <section>
      <Container fluid>
        <BreadcrumbDashboard leftTitle="Dashboard" />
        <div className="dashboard-body-top-content-header mt-3">Hello</div>
      </Container>
    </section>
  );
}
