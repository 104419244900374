import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "reactstrap";
import CustomInput from "../../../../components/Common/InputComponent/CustomInput";
import { SendRequestSchema } from "../../../../Schemas/SendRequestSchema";
import TextError from "./../../../../components/Common/InputComponent/TextError";
import { sendDemoRequest } from "./../../../../store/Dashboard/action";
import Editor from "./QuillEditor";

const EmailTemplate = ({
  subjectValue,
  subjectId,
  body,
  currentPage,
  emailTemplate,
}) => {
  console.log(emailTemplate);
  const dispatch = useDispatch();
  const { authtoken, demoRequestLoading } = useSelector((state) => ({
    authtoken: state.Login.token,
    demoRequestLoading: state.DashboardReducer.demoRequestLoading,
  }));
  console.log("subjectValue", subjectValue);
  const initialValues = {
    subject:
      typeof subjectValue == "object" ? subjectValue.subject : subjectValue,
    subjectId: emailTemplate?._id,
    email: emailTemplate?.userEmail,
  };

  const [values, setValues] = useState(null);
  const [value2, setValue2] = useState("");
  const handlesubmit = (values) => {
    console.log("values", values);
    console.log("hvjh");

    let newData = {
      subjectId: values?.subjectId,
      subject: values?.subject,
      detailsMail: value2,
    };
    // console.log("=====================>",newData, body);
    dispatch(sendDemoRequest(authtoken, newData, body, currentPage));
  };

  // const [value2, setValue2] = useState("");
  useEffect(() => {
    setValue2();
  }, []);
  console.log(subjectValue);
  return (
    <>
      <div className="email-template-main-wrap">
        <table
          role="presentation"
          style={{
            borderCollapse: "collapse",
            borderSpacing: 0,
            textAlign: "left",
            width: "100%",
          }}
        >
          <tbody>
            <tr>
              <td>
                {" "}
                <img
                  src="https://i.ibb.co/3c4GsqD/maacLogo.png"
                  align="left"
                  alt=""
                  style={{ marginBottom: 15 }}
                />
              </td>
            </tr>

            <tr>
              <td colSpan={2}>
                <br />
                <Formik
                  initialValues={values || initialValues}
                  validationSchema={SendRequestSchema}
                  onSubmit={handlesubmit}
                  enableReinitialize={true}
                >
                  {({ values, errors, isSubmitting }) => (
                    <Form className="from-group mt-4">
                      {/* {console.log('values', values, errors)} */}
                      <Row>
                        <Col md="12 mb-2">
                          <Field
                            name="email"
                            label={"Email"}
                            type="text"
                            component={CustomInput}
                            disabled={emailTemplate?.totalMail > 1}
                            placeholder="email"
                          />
                        </Col>
                        <Col md="12 mb-2">
                          <Field
                            name="subject"
                            label={"Subject"}
                            type="text"
                            disabled
                            component={CustomInput}
                            placeholder="Enter Name"
                          />
                        </Col>

                        <Col md="12">
                          <div>
                            <Editor
                              placeholder={"Type"}
                              name="q"
                              handleChange={(e) => {
                                setValue2(e);
                              }}
                              value={value2}
                            />
                          </div>
                          <ErrorMessage name={`q`} component={TextError} />
                        </Col>
                      </Row>
                      {demoRequestLoading ? (
                        <div className="d-flex  mt-3">
                          <button
                            className="form-submit-btn btn btn-secondary"
                            style={{ padding: "8px 16px", width: "110px" }}
                          >
                            <Spinner color="primary" />
                          </button>
                        </div>
                      ) : (
                        <div className="mt-3">
                          <button
                            className="form-submit-btn btn btn-secondary"
                            disabled={emailTemplate?.totalMail > 1}
                            type="submit"
                            style={{ padding: "8px 16px", width: "110px" }}
                          >
                            Submit
                          </button>
                        </div>
                      )}
                    </Form>
                  )}
                </Formik>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default EmailTemplate;
