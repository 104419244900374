import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export default function ViewPDF({ pdfFile, toggle }) {
  const [pageNumber, setPageNumber] = useState(1);

  return (
    <div>
      <Document
        file={pdfFile}
        onLoadSuccess={({ numPages }) => {
          setPageNumber(numPages);
          console.log(`Number of pages: ${numPages}`);
        }}
      >
        <div
          className="scrollable"
          style={{
            overflowX: "auto",
          }}
        >
          {[...Array(pageNumber)]?.map((_, idx) => (
            <Page
              pageNumber={idx + 1}
              style={{
                width: "auto",
                height: "auto",
                position: "absolute",
                top: 0,
                left: 0,
              }}
              key={idx}
            />
          ))}
        </div>
      </Document>
    </div>
  );
}
