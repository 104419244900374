import React from "react";
import BannerImage from "../../../assets/image/others/bkash-new-banner.jpg";

export default function CommercialMeetHeader() {
  return (
    <div
      className="meet-header-banner"
      // style={{
      //   background: `linear-gradient(rgba(127, 12, 64, 0.9), rgba(127, 12, 64, 0.9)), url(${MeetBanner})`,
      // }}

      style={{
        backgroundImage: `url(${BannerImage})`,
      }}
    >
      {/* <div className="mobile-wrapper header-area">
        <div className="header-content">
          <div>
            <img src={WhiteLogo} alt="bkash" />
            <h3>Commercial Meet</h3>
            <h2>2024</h2>
          </div>
        </div>
      </div> */}
    </div>
  );
}
