// get categories
export const GET_CATEGORIES_LOADED = "GET_CATEGORIES_LOADED";
export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_CATEGORIES_ERROR = "GET_CATEGORIES_ERROR";

// add categories
export const ADD_CATEGORIES_LOADED = "ADD_CATEGORIES_LOADED";

// delete category
export const DELETE_CATEGORIES_LOADED = "DELETE_CATEGORIES_LOADED";
