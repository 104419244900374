import { FORM_DATA } from "./actionType";

const initialState = {
  demoReq: {},
  //   name: "",
  //   subject: "",
  //   phoneNumber: "",
  //   officeEmail: "",
  //   position: "",
  //   officeAddress: "",
};
const demoReqFormData = (state = initialState, action) => {
  switch (action.type) {
    case FORM_DATA:
      return {
        ...state,
        demoReq: action.payload,
      };

    default:
      return state;
  }
};
export default demoReqFormData;
