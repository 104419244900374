import {
  GET_USER_LIST,
  GET_USER_LIST_FAILED,
  GET_USER_LIST_SUCCESS,
  GET_WINNER,
  GET_WINNER_FAILED,
  GET_WINNER_SUCCESS,
  REMOVE_WINNER,
  REMOVE_WINNER_FAIL,
  REMOVE_WINNER_SUCCESS,
  RESET_WINNER,
  RESET_WINNER_FAIL,
  RESET_WINNER_SUCCESS,
  SELECT_WINNER,
  SELECT_WINNER_FAIL,
  SELECT_WINNER_SUCCESS,
  STORE_RAFFLE_DATA,
} from "./actionTypes";

export const resetWinner = (token) => {
  return {
    type: RESET_WINNER,
    payload: { token },
  };
};
export const resetWinnerSuccess = (message) => {
  return {
    type: RESET_WINNER_SUCCESS,
    payload: { message },
  };
};

export const resetWinnerFail = (message) => {
  return {
    type: RESET_WINNER_FAIL,
    payload: { message },
  };
};

export const removeWinner = (id) => {
  console.log(id);

  return {
    type: REMOVE_WINNER,
    payload: { id },
  };
};
export const removeWinnerSuccess = (message) => {
  return {
    type: REMOVE_WINNER_SUCCESS,
    payload: { message },
  };
};

export const removeWinnerFail = (message) => {
  return {
    type: REMOVE_WINNER_FAIL,
    payload: { message },
  };
};

export const selectWinner = (token) => {
  return {
    type: SELECT_WINNER,
    payload: { token },
  };
};
export const selectWinnerSuccess = (message) => {
  return {
    type: SELECT_WINNER_SUCCESS,
    payload: { message },
  };
};

export const selectWinnerFail = (message) => {
  return {
    type: SELECT_WINNER_FAIL,
    payload: { message },
  };
};

export const getUserList = (token) => {
  return {
    type: GET_USER_LIST,
    payload: { token },
  };
};

export const getUserListSuccess = (values) => {
  return {
    type: GET_USER_LIST_SUCCESS,
    payload: { values },
  };
};

export const getUserListFail = (message) => {
  return {
    type: GET_USER_LIST_FAILED,
    payload: { message },
  };
};

export const getWinnerList = (token) => {
  return {
    type: GET_WINNER,
    payload: { token },
  };
};

export const getWinnerListSuccess = (values) => {
  return {
    type: GET_WINNER_SUCCESS,
    payload: { values },
  };
};

export const getWinnerListFail = (message) => {
  return {
    type: GET_WINNER_FAILED,
    payload: { message },
  };
};
export const storeRaffleData = (name, data) => ({
  type: STORE_RAFFLE_DATA,
  payload: { name, data },
});
