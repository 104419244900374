import { call, put, takeEvery } from "redux-saga/effects";
import { toaster } from "../../../Dashboard/Components/Helpers/Custom/Toaster";
import { del, get, patch, post } from "../../../Dashboard/helpers/api_helper";
import { getAllHero, getHeroFail } from "./action";
import {
  ADD_HERO_LOADED,
  DELETE_HERO_LOADED,
  GET_HERO_LOADED,
} from "./actionTypes";

function* getHero({ payload: { type = "" } }) {
  try {
    const url = `/hero-section/data?type=${type}`;
    const response = yield call(get, url);

    yield put(getAllHero(response));
  } catch (error) {
    yield put(getHeroFail(error));
  }
}

function* addHero({
  payload: {
    data,
    payload: { setSubmitting, resetForm, toggle, id, type },
  },
}) {
  try {
    const url = id ? `/hero-section/${id}` : `/hero-section`;
    const response = yield call(id ? patch : post, url, data);

    if (response) {
      toaster("success", `${id ? "Update" : "Add"} ${type} data successfully!`);
    }

    yield setSubmitting(false);
    yield resetForm(true);
    yield toggle(true);
  } catch (error) {
    const err =
      error?.response?.data?.message ||
      `${id ? "Update" : "Add"} ${type} data fail!`;
    toaster("error", err);
    yield setSubmitting(false);
  }
}

function* deleteHero({
  payload: {
    id,
    payload: { toggle },
  },
}) {
  try {
    const url = `/hero-section/${id}`;
    const response = yield call(del, url);

    if (response) {
      toaster("success", "Section delete successfully!");
    }
    yield toggle(true);
  } catch (error) {
    const err = error?.response?.data?.message || "Section delete fail!";
    toaster("error", err);
  }
}

function* heroSaga() {
  yield takeEvery(GET_HERO_LOADED, getHero);
  yield takeEvery(ADD_HERO_LOADED, addHero);
  yield takeEvery(DELETE_HERO_LOADED, deleteHero);
}

export default heroSaga;
