export const fackData = [
  {
    name: "kayser",
    organizationName: "kayser MAAC",
    Email: "kayser@gmail.com",
    DemoRequest: "kayser",
    Status: "pending",
    Action: "kayser",
    subject: "fieldx",
  },
  {
    name: "kayser",
    organizationName: "kayser MAAC",
    Email: "kayser@gmail.com",
    DemoRequest: "kayser",
    Status: "pending",
    Action: "kayser",
    subject: "xelerator",
  },
  {
    name: "kayser",
    organizationName: "kayser MAAC",
    Email: "kayser@gmail.com",
    DemoRequest: "kayser",
    Status: "pending",
    Action: "kayser",
    subject: "axs",
  },
  {
    name: "kayser",
    organizationName: "kayser MAAC",
    Email: "kayser@gmail.com",
    DemoRequest: "kayser",
    Status: "pending",
    Action: "kayser",
    subject: "calibrax",
  },
];
