import React from "react";
import "./footer.css";
import fLogo from "./img/MAAC.svg";
const EsQFooter = () => {
  return (
    <footer className="footer-main-area e-square-footer tas-footer">
      <div className="footer-bottom">
        <div className="container">
          <div className="footer-bottom-inner">
            <div className="footer-bottom-left">
              <img src={fLogo} alt="" />
            </div>
            <div className="footer-bottom-right">
              <p className="mobile-margin-top-gap">
                © {new Date().getFullYear()} MAAC
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default EsQFooter;
