import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import CustomInput from "../../../components/Common/InputComponent/CustomInput";
import { addCategoryLoaded } from "../../../Redux/Centro/Category/action";
import CustomModal from "../../Components/Layout/CustomModal";
import { createCategorySchema } from "../schemas/categorySchema";

const initialValue = {
  name: "",
  order: "",
  details: "",
};

export default function CreateCategory({ open, toggle, data, edit, view }) {
  const [values, setValues] = useState(null);
  const dispatch = useDispatch();

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    dispatch(
      addCategoryLoaded({
        data: values,
        payload: { setSubmitting, resetForm, toggle, id: data?._id || null },
      })
    );
  };

  useEffect(() => {
    if (edit || view) {
      const obj = {};
      obj.name = data?.name || "";
      obj.order = data?.order || "";
      obj.details = data?.details || "";

      setValues(obj);
    }
  }, [edit, data, view]);

  return (
    <CustomModal
      title={
        edit ? "Edit Category" : view ? "View Category" : "Create Category"
      }
      open={open}
      centered
      // modalSize="xl"
      toggle={() => toggle(false)}
    >
      <Formik
        initialValues={values || initialValue}
        validationSchema={createCategorySchema}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {({ values, errors, isSubmitting }) => (
          <Form>
            <Row className="add_category_section my-3">
              <Col md="12 mb-2">
                <Field
                  name="name"
                  label="Category Name"
                  type="text"
                  component={CustomInput}
                  placeholder="Category Name"
                  disabled={view}
                />
              </Col>
              <Col md="12 mb-2">
                <Field
                  name="order"
                  label="Order"
                  type="number"
                  min={1}
                  component={CustomInput}
                  placeholder="Order"
                  disabled={view}
                />
              </Col>

              <Col>
                <Field
                  name="details"
                  rows={3}
                  label="Category Details"
                  type="textarea"
                  component={CustomInput}
                  placeholder="Category Details"
                  disabled={view}
                />
              </Col>
            </Row>

            {!view && (
              <div className="mt-4 d-flex justify-content-start gap-2">
                <button
                  className="btn create-btn"
                  disabled={isSubmitting}
                  type="submit"
                >
                  {isSubmitting
                    ? "Submitting..."
                    : edit
                    ? "Update Category"
                    : "Save Category"}{" "}
                </button>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
}
