import React from 'react';
import { Card } from 'react-bootstrap';

const CardComponent = ({ className, bodyClass, children, ...rest }) => {
  return (
    <React.Fragment>
      <Card className={className + ' border-0 shadow-sm'} {...rest}>
        <Card.Body className={bodyClass}>{children}</Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default CardComponent;
