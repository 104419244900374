// get Hero
export const GET_HERO_LOADED = "GET_HERO_LOADED";
export const GET_HERO = "GET_HERO";
export const GET_HERO_ERROR = "GET_HERO_ERROR";

// add Hero
export const ADD_HERO_LOADED = "ADD_HERO_LOADED";

// delete Hero
export const DELETE_HERO_LOADED = "DELETE_HERO_LOADED";
