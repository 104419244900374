import React, { useState } from "react";
import ClearIcon from "../../../assets/image/close-icon.png";
import EventIcon from "../../../assets/image/others/EventIcon";
import ItineraryImage from "../../../assets/image/others/event-photo.jpeg";
import CustomModal from "../Layout/CustomModal";
import BackPage from "./BackPage";
import EventDetailsCard from "./EventDetailsCard";
import EventHeader from "./EventHeader";

export default function EventDetailsHeader() {
  const [showPDF, setShowPDF] = useState(false);

  const toggle = () => {
    setShowPDF(false);
  };

  return (
    <div>
      {showPDF ? (
        <CustomModal
          open={true}
          toggle={toggle}
          size={"sm"}
          modalBody="contact-modal"
        >
          <div className="d-flex justify-content-end mb-2 position-relative">
            <button
              className="btn p-2 d-flex justify-content-center align-items-center"
              style={{ borderRadius: "50%", width: 30, height: 30 }}
              onClick={toggle}
            >
              <img style={{ height: 12 }} src={ClearIcon} alt="" />
            </button>
          </div>
          {/* <div className="contact-modal-pdf">
            <ViewPDF pdfFile={TEST_PDF} toggle={toggle} />
          </div> */}
          <div>
            <img
              className="w-100"
              style={{ objectFit: "cover" }}
              src={ItineraryImage}
              alt=""
            />
          </div>
        </CustomModal>
      ) : null}

      <BackPage
        title="Itinerary"
        backLink={`/commercial-meet-2024`}
        downloadDocument={ItineraryImage}
        ext="jpeg"
      />
      <EventHeader />
      <EventDetailsCard
        title="Itinerary"
        icon={EventIcon}
        handleCardClick={() => setShowPDF(true)}
      />
    </div>
  );
}
