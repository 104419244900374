import { GET_USER_LIST, GET_USER_LIST_FAILED, GET_USER_LIST_SUCCESS, GET_WINNER, GET_WINNER_FAILED, GET_WINNER_SUCCESS, RESET_WINNER, RESET_WINNER_FAIL, RESET_WINNER_SUCCESS, SELECT_WINNER, SELECT_WINNER_FAIL, SELECT_WINNER_SUCCESS, STORE_RAFFLE_DATA } from "./actionTypes"

  
  const initialState = {
   
    adding: false,
    message: "",
    error: "",
    userList: [],
    winnerList: [],
    findWinner: [],
    raffleData: [],
    findWinnerLoading: false,
   
    userListLoading: false,
    winnerListLoading: false
    

  }
  
  const RaffleReducer = (state = initialState, action) => {
    switch (action.type) {
        case RESET_WINNER:
            state = {
              ...state,
              adding: true,
            }
            break
          case RESET_WINNER_SUCCESS:
            state = {
              ...state,
              message: action.payload.message,
              adding: false,
            }
            break
          case RESET_WINNER_FAIL:
            state = {
              ...state,
              error: action.payload.message,
              adding: false,
            }
            break
        case SELECT_WINNER:
            state = {
              ...state,
              findWinnerLoading: true,
            }
            break
          case SELECT_WINNER_SUCCESS:
            state = {
              ...state,
              findWinnerLoading: false,
              findWinner: action.payload,
            }
            break
          case SELECT_WINNER_FAIL:
            state = {
              ...state,
              findWinnerLoading: false,
              message: action.payload.message,
            }
            break

      case GET_USER_LIST:
        state = {
          ...state,
          userListLoading: true,
        }
        break
      case GET_USER_LIST_SUCCESS:
        state = {
          ...state,
          userListLoading: false,
          userList: action.payload.values,
        }
        break
      case GET_USER_LIST_FAILED:
        state = {
          ...state,
          userListLoading: false,
          message: action.payload.message,
        }
        break
      
       
        case GET_WINNER:
            state = {
              ...state,
              winnerListLoading: true,
            }
            break
          case GET_WINNER_SUCCESS:
            state = {
              ...state,
              winnerListLoading: false,
              winnerList: action.payload,
            }
            break
          case GET_WINNER_FAILED:
            state = {
              ...state,
              winnerListLoading: false,
              message: action.payload.message,
            }
            break
            case STORE_RAFFLE_DATA:
              return {
                ...state,
                [action.payload.name]: action.payload.data,
              };
      
      default:
        state = { ...state }
        break
    }
  
    return state
  }
  
  export default RaffleReducer
  