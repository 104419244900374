import React from "react";
import { useHistory } from "react-router-dom";
import BackIcon from "../../../assets/image/others/BackIcon";
import DownloadIcon from "../../../assets/image/others/DownloadIcon";

export default function BackPage({
  title,
  backLink,
  downloadDocument,
  ext = "pdf",
}) {
  const history = useHistory();

  return (
    <div style={{ backgroundColor: "#fff" }}>
      <div className="mobile-wrapper">
        <div className="back-header">
          <div className="title-area">
            <BackIcon
              onClick={() =>
                backLink ? history.push(backLink) : history.goBack()
              }
              style={{ cursor: "pointer" }}
            />
            <h5>{title}</h5>
          </div>

          <a href={downloadDocument} download={`${title}.${ext}`}>
            <DownloadIcon style={{ cursor: "pointer" }} />
          </a>
        </div>
      </div>
    </div>
  );
}
