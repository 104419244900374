import { useSelector } from "react-redux";
import { TabTitle } from "../../../../utils/GeneralFunctions";
import "../../../sep.scss";
import EsQFooter from "./EsQFooter";
import "./footer.css";
import "./lafarge.scss";
import LafargeHeader from "./LafargeHeader";
import LafargeHero from "./LafargeHero";
import StepOne from "./LafargeQues/StepOne";
import StepTwo from "./LafargeQues/StepTwo";
const CampusActivation = () => {
  TabTitle("XamPro Feedback");
  const { step } = useSelector((state) => ({
    step: state.lafarge.step,
  }));

  // console.log(`step`, step);

  const loadData = () => {
    switch (step) {
      case 1:
        return <StepOne />;
      case 2:
        return <StepTwo />;

      default:
        return "No data";
    }
  };
  return (
    <div className="tas lafarge-main-wrap xam-pro">
      <LafargeHeader />
      {step !== 1 && <LafargeHero />}
      {loadData()}
      <EsQFooter />
      {/* LafargeHero */}
    </div>
  );
};

export default CampusActivation;
