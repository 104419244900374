///1
export const marketKnowledge = [
  {
    question: "The contents covered in “Market knowledge” properly addressed.",
    answer: "",
    questionformat: "radio",
    isActive: true,
    isDone: false,
  },
  {
    question:
      "The concept “Beat the market” & “Behind the market” was relevant to the industry  ",
    answer: "",
    questionformat: "radio",
    isActive: false,
    isDone: false,
  },
  {
    question:
      "The trainer was able to cover the key aspects of market knowledge ",
    answer: "",
    questionformat: "radio",
    isActive: false,
    isDone: false,
  },
  {
    question:
      "The training exercise covered in the session precisely addresses the importance of “Gain creators” and “Pain relievers” ",
    answer: "",
    questionformat: "radio",
    isActive: false,
    isDone: false,
  },
];
///2

export const Salesmanship = [
  {
    question:
      "The “salesmanship” section was relevant, and can be applied in day-to-day sales",
    answer: "",
    questionformat: "radio",
    isActive: true,
    isDone: false,
  },
  {
    question:
      "The 5 pillars of modern selling  address the shift that is required for the participants",
    answer: "",
    questionformat: "radio",
    isActive: false,
    isDone: false,
  },
  {
    question:
      "The differences between <u>traditional selling</u> vs <u>professional selling</u> were properly identified by the trainers    ",
    answer: "",
    questionformat: "radio",
    isActive: false,
    isDone: false,
  },
];
////3
export const EBITADa = [
  {
    question:
      "The training idenfied and applied  the importance of EBITDA in sales",
    answer: "",
    questionformat: "radio",
    isActive: true,
    isDone: false,
  },
  {
    question:
      "The “profit calculation” exercise was intriguing in identifying the parameters of cement selling",
    answer: "",
    questionformat: "radio",
    isActive: false,
    isDone: false,
  },
  {
    question:
      "The training session properly identified how growth can be achieved by utilizing the sales levers (revenue, price & quantity) ",
    answer: "",
    questionformat: "radio",
    isActive: false,
    isDone: false,
  },
];
export const WinningHabits = [
  {
    question:
      "The concept “unlearn” is relevant to not only the cement industry market practice",
    answer: "",
    questionformat: "radio",
    isActive: true,
    isDone: false,
  },
  {
    question:
      "Does <u> growth mindset </u> help in creating the winning habits for the cement salesman",
    answer: "",
    questionformat: "radio",
    isActive: false,
    isDone: false,
  },
  {
    question:
      "The pace of the section was appropriate to the content and attendees",
    answer: "",
    questionformat: "radio",
    isActive: false,
    isDone: false,
  },
];
export const GoToMarket = [
  {
    question:
      "“Proper Go-To-Market plan defines how well you are going to do in the market” –  Do you agree with the statement?",
    answer: "",
    questionformat: "radio",
    isActive: true,
    isDone: false,
  },
  {
    question: "The training session covered the importance of GTM extensively",
    answer: "",
    questionformat: "radio",
    isActive: false,
    isDone: false,
  },
  {
    question:
      "In the training, managing customer portfolio was identified as the baseline for commercial planning – Do you agree?",
    answer: "",
    questionformat: "radio",
    isActive: false,
    isDone: false,
  },
];
export const SalesProcess = [
  {
    question:
      "Do you think Sandler’s method was best among the processes available in cement industry? ",
    answer: "",
    questionformat: "radio",
    isActive: true,
    isDone: false,
  },
  {
    question:
      "Do you think practicing “Up front contact” will create more win-win scenario?",
    answer: "",
    questionformat: "radio",
    isActive: false,
    isDone: false,
  },
  {
    question:
      "“Budget” is the most crucial step in cement industry selling scenario - Do you agree? ",
    answer: "",
    questionformat: "radio",
    isActive: false,
    isDone: false,
  },
];
export const RecoveryManagement = [
  {
    question:
      "“DSO” is a  crucial recovery management element  had been covered in this training.",
    answer: "",
    questionformat: "radio",
    isActive: true,
    isDone: false,
  },
  {
    question:
      "“Company DSO matters most as opposed to Distributor DSO in market” – do you agree?",
    answer: "",
    questionformat: "radio",
    isActive: false,
    isDone: false,
  },
  {
    question:
      "The trainers were well prepared and able to answer any questions",
    answer: "",
    questionformat: "radio",
    isActive: false,
    isDone: false,
  },
];
