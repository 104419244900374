import { ErrorMessage, Field } from "formik";
import React from "react";
import { Input } from "reactstrap";
import TextError from "./TextError";

function RadioButtons(props) {
  const { label, name, options, mainClass, ...rest } = props;
  // console.log(label);
  return (
    <div className={mainClass + ""}>
      <label
        className="d-block"
        dangerouslySetInnerHTML={{ __html: label }}
      ></label>
      <Field name={name}>
        {({ field }) => {
          return options.map((option) => {
            return (
              <React.Fragment key={option.key}>
                <Input
                  type="radio"
                  id={option.value + field?.name}
                  {...field}
                  {...rest}
                  value={option.value}
                  checked={field.value === option.value}
                />
                <label
                  title={option.message}
                  htmlFor={option.value + field?.name}
                >
                  {option.key}
                </label>
              </React.Fragment>
            );
          });
        }}
      </Field>
      <ErrorMessage component={TextError} name={name} />
    </div>
  );
}

export default RadioButtons;
