import { combineReducers } from "redux";
import categoryReducer from "../Redux/Centro/Category/categoryReducer";
import heroReducer from "../Redux/Centro/Hero/productReducer";
import productReducer from "../Redux/Centro/Product/productReducer";
import lafarge from "./../Redux/Lafarge/reducers";
import getCcbSctiveWheel from "./../Redux/ccb/reducer";
import demoReqFormData from "./../Redux/demoFormReq/reducers";
import SvgItemChange from "./../Redux/reducers/SvgItemChange";
import DashboardReducer from "./Dashboard/reducer";
import RaffleReducer from "./Raffle/reducer";
import requestListReducer from "./downloadExcel/reducer";
import Layout from "./layout/reducer";
import Login from "./login/reducer";
const rootReducer = combineReducers({
  Login,
  DashboardReducer,
  Layout,
  SvgItemChange,
  lafarge,
  demoReqFormData,
  RaffleReducer,
  requestList: requestListReducer,
  categories: categoryReducer,
  products: productReducer,
  hero: heroReducer,
  ccbData: getCcbSctiveWheel,
});

export default rootReducer;
