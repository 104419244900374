// get products
export const GET_PRODUCT_LOADED = "GET_PRODUCT_LOADED";
export const GET_PRODUCT = "GET_PRODUCT";
export const GET_PRODUCT_ERROR = "GET_PRODUCT_ERROR";

// add product
export const ADD_PRODUCT_LOADED = "ADD_PRODUCT_LOADED";

// delete product
export const DELETE_PRODUCT_LOADED = "DELETE_PRODUCT_LOADED";

// get single products
export const GET_SINGLE_PRODUCT_LOADED = "GET_SINGLE_PRODUCT_LOADED";
export const GET_SINGLE_PRODUCT = "GET_SINGLE_PRODUCT";
export const GET_SINGLE_PRODUCT_ERROR = "GET_SINGLE_PRODUCT_ERROR";
