import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import ReactDOM from "react-dom";
// import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.min.css";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { PersistGate } from "redux-persist/integration/react";

import "./Dashboard/assests/scss/style.scss";
// Import scss
import App from "./AppMain";
// import "./Dashboard/assests/scss/theme.scss";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import { persistor, store } from "./store";

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));

reportWebVitals();
