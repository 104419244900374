import {
  ADD_HERO_LOADED,
  DELETE_HERO_LOADED,
  GET_HERO,
  GET_HERO_ERROR,
  GET_HERO_LOADED,
} from "./actionTypes";

const initialState = {
  loading: false,
  hero: {},
  error: "",
};

const heroReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_HERO_LOADED:
      return {
        ...state,
        loading: true,
      };
    case GET_HERO:
      return {
        ...state,
        loading: false,
        error: "",
        hero: action.payload,
      };
    case GET_HERO_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ADD_HERO_LOADED:
      return {
        ...state,
      };

    case DELETE_HERO_LOADED:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default heroReducer;
