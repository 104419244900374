import React, { useState } from "react";
import LafargeHeader from "../../SEP/Components/Pages/Lafarge/LafargeHeader";
import ElevateFeedbackForm from "./ElevateFeedbackForm";
import ElevateFeedbackForm2 from "./ElevateFeedbackForm2";
import ElevateHero from "./ElevateHero";
import ElevateSignup from "./ElevateSignup";

export default function Elevate() {
  const [isSignup, setSignup] = useState(1);

  const [valueData, setValueData] = useState({
    signup: {},
    questions: {},
    feedback: {},
  });

  //   console.log(valueData);

  const handleData = (values) => {
    setValueData((prev) => ({
      ...prev,
      ...values,
    }));
  };

  const handleSignup = () => {
    setSignup((prev) => prev + 1);
  };

  return (
    <div className="tas lafarge-main-wrap">
      <LafargeHeader />
      {isSignup === 1 ? (
        <ElevateSignup handleSignup={handleSignup} handleData={handleData} />
      ) : isSignup === 2 ? (
        <>
          <ElevateHero />
          <ElevateFeedbackForm
            handleSignup={handleSignup}
            handleData={handleData}
            valueData={valueData}
          />
        </>
      ) : isSignup === 3 ? (
        <ElevateFeedbackForm2
          handleSignup={handleSignup}
          handleData={handleData}
          valueData={valueData}
        />
      ) : null}
    </div>
  );
}
