import { Field, Form, Formik } from "formik";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { elevateSignupSchema } from "../../SEP/Components/Schemas/LafargeScema";
import CardComponent from "../../components/Common/CardComponent";
import CustomInput from "../../components/Common/InputComponent/CustomInput";

const initialValues = {
  name: "",
  email: "",
  department: "",
  designation: "",
};

const ElevateSignup = ({ handleSignup, handleData }) => {
  const onSubmit = (values) => {
    if (Object.values(values)?.length === 4) {
      handleData({
        signup: values,
      });
      handleSignup();
    }
  };

  return (
    <React.Fragment>
      <div className="lafarge-form-main-wrap py-0">
        <Row className="">
          <Col md="7" className="mx-auto ">
            <CardComponent className={"lafarge-inner-form-wrap"}>
              <Col md="6" className="offset-md-3">
                <Formik
                  initialValues={initialValues}
                  validationSchema={elevateSignupSchema}
                  onSubmit={onSubmit}
                  enableReinitialize={true}
                >
                  {({ values, errors, isSubmitting }) => (
                    <Form className="mt-4">
                      {/* {console.log('values', values, errors)} */}
                      <Row>
                        <Col md="12 mb-2">
                          <Field
                            name="name"
                            label={"Full name"}
                            type="text"
                            component={CustomInput}
                            placeholder="Enter Name"
                          />
                        </Col>
                        <Col md="12 " style={{ position: "relative" }}>
                          {/* <span className="start-phone-number">+880</span> */}
                          <Field
                            // className="start-phone-number"
                            label={"Email"}
                            name="email"
                            type="email"
                            component={CustomInput}
                            placeholder="Enter your email"
                          />
                        </Col>
                        <Col md="12 ">
                          <Field
                            name="department"
                            label={"Department"}
                            type="text"
                            component={CustomInput}
                            placeholder="Enter department"
                          />
                        </Col>
                        <Col md="12 ">
                          <Field
                            name="designation"
                            label={"Designation"}
                            type="text"
                            component={CustomInput}
                            placeholder="Enter designation"
                          />
                        </Col>
                      </Row>

                      <div className="mt-4">
                        <button
                          className="btn lafarge-first-stap-button w-100"
                          type="submit"
                        >
                          {"Signup"}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </Col>
            </CardComponent>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default ElevateSignup;
