import React from "react";

export default function TravelIcon() {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M8.75 2C8.33579 2 8 2.33579 8 2.75C8 3.16421 8.33579 3.5 8.75 3.5H9V5H8.25C6.45507 5 5 6.45507 5 8.25V17.25C5 18.602 5.82552 19.7611 7 20.2509V21.25C7 21.6642 7.33579 22 7.75 22C8.16421 22 8.5 21.6642 8.5 21.25V20.5H15.5V21.25C15.5 21.6642 15.8358 22 16.25 22C16.6642 22 17 21.6642 17 21.25V20.2509C18.1745 19.7611 19 18.602 19 17.25V8.25C19 6.45507 17.5449 5 15.75 5H15V3.5H15.25C15.6642 3.5 16 3.16421 16 2.75C16 2.33579 15.6642 2 15.25 2H8.75ZM13.5 3.5V5H10.5V3.5H13.5ZM8 9.75C8 9.33579 8.33579 9 8.75 9H15.25C15.6642 9 16 9.33579 16 9.75C16 10.1642 15.6642 10.5 15.25 10.5H8.75C8.33579 10.5 8 10.1642 8 9.75Z"
          fill="#E2136E"
        />
      </svg>
    </div>
  );
}
