import React from "react";
import { Container } from "react-bootstrap";
import heroBg from "./img/lafarsHero.jpeg";
const LafargeHero = () => {
  return (
    <div
      className="hero-container-main-wrap hero-sep-main tas-hero"
      style={{ backgroundImage: `url(${heroBg})` }}
    >
      <div className="hero-main-area-wrap">
        <Container>
          <div className="hero-inner-area-wrap">
            <h6>Feedback </h6>
            <h1>
              Advanced sales excellence: <br /> The art of winning
            </h1>
          </div>
        </Container>
      </div>
      {/* <VideoPlayer
        className="video"
        src={
          "https://sgp1.digitaloceanspaces.com/staging-salesx/1652868443863-pexels-alena-darmel-7646697.mp4"
        }
        autoPlay={true}
        muted={true}
      /> */}
    </div>
  );
};

export default LafargeHero;
