import React from "react";
import { Container } from "react-bootstrap";
import ReactPlayer from "react-player";
import ElevateLogo from "../../assets/image/elevateLightLogo.svg";
import HeroVideo from "../../assets/videos/micro.mp4";
// import heroBg from "./img/lafarsHero.jpeg";
// import heroBgNewZealand from "./img/power-point-mestery.jpeg";

const ElevateHero = () => {
  return (
    <div
      className="hero-container-main-wrap hero-sep-main tas-hero position-relative"
      //   style={{
      //     backgroundImage:
      //       checkPath === "/lafarge"
      //         ? `url(${heroBg})`
      //         : `url(${heroBgNewZealand})`,
      //   }}
    >
      <div className="elevate-hero">
        <ReactPlayer
          url={HeroVideo}
          controls={false}
          width="100%"
          height="100%"
          playing={true}
          loop={true}
          muted={true}
          className="video-player-inner-wrap"
        />
      </div>
      <div className="hero-main-area-wrap">
        <Container>
          <div className="hero-inner-area-wrap">
            <h6>Evaluation</h6>
            <div className="mb-2">
              <img src={ElevateLogo} alt="" />
            </div>
            <h1
              style={{
                textTransform: "capitalize",
              }}
            >
              Value Workshop 2024
            </h1>
          </div>
        </Container>
      </div>
      {/* <VideoPlayer
        className="video"
        src={
          "https://sgp1.digitaloceanspaces.com/staging-salesx/1652868443863-pexels-alena-darmel-7646697.mp4"
        }
        autoPlay={true}
        muted={true}
      /> */}
    </div>
  );
};

export default ElevateHero;
