import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Button, Spinner } from "reactstrap";
import "../../../Dashboard/assests/login.scss";
import "../../../Dashboard/assests/scss/theme.scss";
import {
  getProject,
  getProjectCount,
  sendAlertMail,
  sendNotifyMail,
} from "../../../store/Dashboard/action";

import BreadcrumbDashboard from "../../Breadcrumb/BreadcrumbDashboard";
import { fackData } from "../../fackData/fackData";
// import { getTopBanner, storeDashboardData } from "../../store/Dashboard/action";
import { getRequestList } from "../../../store/downloadExcel/action";
import CardComponent from "../Common/CardComponent";
import CustomCard from "../Common/CustomCard";
import CustomTable from "../Layout/CustomTable";
import ExcelDownloader from "./DemoRequestDownload";
import EmailTemplate from "./EmailTemplates/EmailTemplate";
import "./dashboard.scss";
import box from "./img/box.svg";
const tableHead = [
  "Sl. No.",
  "Name",
  "Organization Name",
  "Email ",
  "Request date",
  "Demo account",
  "Total repeat mail",
  "Last repeat mail's date",
  "Total notify mail",
  "Last notify mail's date",
  "Action",
];
const Dashboard = () => {
  const dispatch = useDispatch();

  // eslint-disable-next-line no-unused-vars
  const [currentPage, setCurrentPage] = useState(0);

  // eslint-disable-next-line no-unused-vars
  const [handleList, setHandleList] = useState(10);
  const {
    startDateRange,
    endDateRange,
    authtoken,
    topBanner,
    project,
    subject,
    projectCount,
    demoRequestLoading,
    alertMailLoading,
    notifyMailLoading,
    requestList,
  } = useSelector((state) => ({
    authtoken: state.Login.token,
    requestList: state.requestList.requestList,
    startDateRange: state.DashboardReducer.startDateRange,
    endDateRange: state.DashboardReducer.endDateRange,
    subject: state.DashboardReducer.subject,
    project: state.DashboardReducer.project,
    projectCount: state.DashboardReducer.projectCount,
    topBanner: state.DashboardReducer.topBanner,
    demoRequestLoading: state.DashboardReducer.demoRequestLoading,
    alertMailLoading: state.DashboardReducer.alertMailLoading,
    notifyMailLoading: state.DashboardReducer.notifyMailLoading,
  }));
  // console.log("alertMailLoading", alertMailLoading);
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);
  // eslint-disable-next-line no-unused-vars
  const [value, setValue] = useState("");

  let body = {
    endDate: endDateRange,
    startDate: startDateRange,
    subject: subject,
  };
  useEffect(() => {
    dispatch(getProject(authtoken, body, currentPage));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDateRange, startDateRange, subject, currentPage]);
  useEffect(() => {
    dispatch(getProjectCount(authtoken, body));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDateRange, startDateRange, subject]);

  const [emailTemplate, setEmailTemplate] = useState("");
  const [subjectId, setSubjectId] = useState("");
  const [repeatMail, setRepeatMail] = useState("");
  const [notifyMail, setNotifyMail] = useState("");
  const [subjectValue, setSubjectValue] = useState(emailTemplate);
  const [show, setShow] = useState(false);
  const [showNotifyMail, setShowNotifyMail] = useState(false);
  const [name, setName] = useState("");
  const [courseName, setCourseName] = useState("");

  const handleNotifyMailClose = () => setShowNotifyMail(false);
  const handleClose = () => setShow(false);
  const handleShow = (data) => {
    setRepeatMail(data?._id);
    setShow(true);
  };
  const handleNotifyMailShow = (data) => {
    setNotifyMail(data?._id);
    setShowNotifyMail(true);
    setName(data?.userEmail.split("@")[0]);
    setCourseName(data?.subject);
  };
  const handleGetEmailValue = (data) => {
    // console.log("", data);
    if (data) {
      setOpen(true);
      setEmailTemplate(data);
    }
  };
  useEffect(() => {
    setSubjectValue(emailTemplate);
    setSubjectId(subjectId);
  }, [emailTemplate, subjectValue, subjectId]);

  let content;
  if (emailTemplate !== "" && subjectValue !== "") {
    content = (
      <EmailTemplate
        subjectValue={subjectValue}
        subjectId={subjectId}
        body={body}
        currentPage={currentPage}
        emailTemplate={emailTemplate}
      />
    );
  }
  let totalPage = Math.ceil(
    (projectCount?.data?.countData[1] + projectCount?.data?.countData[2]) / 10
  );
  const handleRepeatMail = () => {
    dispatch(sendAlertMail(authtoken, repeatMail, body, currentPage));
  };
  const handleNotifyMail = () => {
    dispatch(sendNotifyMail(authtoken, notifyMail, body, currentPage));
  };
  useEffect(() => {
    if (demoRequestLoading === false) {
      setOpen(false);
    }
  }, [demoRequestLoading]);
  useEffect(() => {
    if (alertMailLoading === false) {
      setShow(false);
    }
  }, [alertMailLoading]);
  useEffect(() => {
    if (notifyMailLoading === false) {
      setShowNotifyMail(false);
    }
  }, [notifyMailLoading]);

  useEffect(() => {
    dispatch(getRequestList({ authtoken, body }));
  }, [authtoken, body.startDate, body.endDate]);
  const tableRef = useRef(null);
  const demoReqData = requestList?.data?.getData;
  console.log(demoReqData);
  return (
    <Container fluid>
      <Modal show={open} onHide={toggle} size="lg">
        <Modal.Header onHide={toggle} charcode="close"></Modal.Header>
        <Modal.Body>{content}</Modal.Body>
      </Modal>
      <BreadcrumbDashboard leftTitle="Dashboard" />
      {/* <button onClick={() => handleDownloadRequestList(body)}>download</button> */}
      <CustomCard />
      <div className="dashboard-body-top-content-header mt-3">
        <Row>
          <Col lg="4">
            <div className="card">
              <div className="card-body">
                <div className="d-flex display_unset justify-content-between text-center">
                  <div>
                    <div className="d-flex justify-content-center">
                      <img src={box} alt="Icon" />
                      <p className="mt-3 ms-2 header-text-color responsive-font-size">
                        Total demo request
                      </p>
                    </div>
                    <h6>
                      {projectCount?.data?.countData[1] +
                        projectCount?.data?.countData[2]}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col lg="4">
            <div className="card">
              <div className="card-body">
                <div className="d-flex display_unset justify-content-between text-center">
                  <div>
                    <div className="d-flex justify-content-center">
                      <img src={box} alt="Icon" />
                      <p className="mt-3 ms-2 header-text-color responsive-font-size">
                        Total demo pending requests
                      </p>
                    </div>
                    <h6>{projectCount?.data?.countData[2]}</h6>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col lg="4">
            <div className="card">
              <div className="card-body">
                <div className="d-flex display_unset justify-content-between text-center">
                  <div>
                    <div className="d-flex justify-content-center">
                      <img src={box} alt="Icon" />
                      <p className="mt-3 ms-2 header-text-color responsive-font-size">
                        Total Sending Demo link
                      </p>
                    </div>
                    <h6>{projectCount?.data?.countData[1]}</h6>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <ExcelDownloader requestList={project?.data?.getData} />
      <CardComponent>
        <Row>
          {project?.data?.getData.length > 0 ? (
            <CustomTable
              paginationClass="paginationContainer text-right"
              data={fackData}
              pageNo={totalPage}
              tableHead={tableHead}
              setCurrentPage={setCurrentPage}
              className="dashboard-table"
              isPagination
              ref={tableRef}
            >
              {project?.data?.getData.length > 0 &&
                project?.data?.getData?.map((data, idx) => (
                  <tr key={idx}>
                    <th scope="row" style={{ paddingLeft: "25px" }}>
                      {(currentPage - 1) * 10 + idx + 1}
                    </th>
                    <td>{data?.subject}</td>
                    <td>{data?.userEmail.split("@")[0]}</td>
                    <td>{data?.userEmail}</td>
                    <td>{data?.createdAt?.slice(0, 10)}</td>
                    <td>{data?.password ? <p>Yes</p> : <p>No</p>}</td>
                    <td>{data?.repeatMail}</td>
                    <td>{data?.lastRepaeatMailDate?.slice(0, 10)}</td>
                    <td>{data?.notifyMail}</td>
                    <td>{data?.lastNotifyMail?.slice(0, 10)}</td>

                    <td>
                      {data.totalMail > 1 ? (
                        <td>
                          <Button
                            onClick={() => {
                              handleGetEmailValue(data);
                            }}
                          >
                            <span>View</span>
                          </Button>{" "}
                          <Button color="info">
                            <span
                              onClick={() => {
                                handleNotifyMailShow(data);
                              }}
                            >
                              Notify mail
                            </span>
                          </Button>
                          <Button color="warning">
                            <span onClick={() => handleShow(data)}>
                              Alert mail
                            </span>
                          </Button>
                        </td>
                      ) : (
                        <Button onClick={toggle}>
                          <span
                            onClick={() => {
                              handleGetEmailValue(data);
                            }}
                          >
                            View
                          </span>
                        </Button>
                      )}
                    </td>
                  </tr>
                ))}
            </CustomTable>
          ) : (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ minHeight: "300px", width: `100%` }}
            >
              <span>No data Available</span>
            </div>
          )}

          {/* table end */}
          {/* modal for detail */}
        </Row>
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header
            closeButton
            charcode="close"
            style={{ borderBottom: "none" }}
          ></Modal.Header>
          <Modal.Body className="text-center">
            <div className="mb-4">
              {/* <img src={Question} alt="Image" style={{ width: "76px" }} /> */}
            </div>
            <h5>Do you want to send this alert mail?</h5>
          </Modal.Body>
          <Modal.Footer
            style={{ borderTop: "none", justifyContent: "center" }}
            className="mb-4"
          >
            <Button
              variant="outline-secondary"
              onClick={handleClose}
              style={{ width: "112px" }}
            >
              Cancel
            </Button>
            {alertMailLoading ? (
              <button
                className="form-submit-btn btn btn-secondary"
                style={{ padding: "8px 16px", width: "110px" }}
              >
                <Spinner color="primary" />
              </button>
            ) : (
              <Button className="btn button" onClick={handleRepeatMail}>
                Yes, I’m Sure
              </Button>
            )}
          </Modal.Footer>
        </Modal>
        <Modal
          show={showNotifyMail}
          onHide={handleNotifyMailClose}
          size="lg"
          centered
        >
          <Modal.Header
            closeButton
            style={{ borderBottom: "none" }}
          ></Modal.Header>
          <Modal.Body className="text-center">
            <div className="mb-4">
              <table
                role="presentation"
                style={{
                  width: "600px",
                  margin: "15px auto",
                  textAlign: "left",
                }}
              >
                <tbody>
                  <tr>
                    <td style={{ padding: "25px 0" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <img
                          src="https://maacsolutions.com/static/media/logo-main.f7c8ff92.svg"
                          align="left"
                          alt=""
                          style={{ marginBottom: "15px" }}
                        />
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td colSpan={2}>
                      <span
                        style={{
                          fontSize: "17px",
                          lineHeight: "26px",
                          marginBottom: "10px",
                          display: "block",
                          fontWeight: 400,
                        }}
                      >
                        <h3>Dear {name},</h3>
                        <p>
                          Hats off! You are using {courseName} for your team’s
                          productivity.
                        </p>
                        <p>
                          In the coming days, the validity of your Demo account
                          will expire. Additionally, there are a few features
                          that you might want to know further. Well, we can also
                          arrange a meeting to discuss in details. Meanwhile,
                          please take a tour of our product in the link below:
                        </p>
                        <p>
                          Choose a time that is convenient time for you, we will
                          follow up, and we are waiting for your reply.
                        </p>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      {" "}
                      <br />
                      Kind regards,
                      <br />
                      <b
                        style={{
                          fontSize: "16px",
                          lineHeight: "24px",
                          marginBottom: "10px",
                          display: "block",
                        }}
                      >
                        Team {courseName}.
                      </b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h5>Do you want to notify this mail?</h5>
          </Modal.Body>
          <Modal.Footer
            style={{ borderTop: "none", justifyContent: "center" }}
            className="mb-4"
          >
            <Button
              variant="outline-secondary"
              onClick={handleNotifyMailClose}
              style={{ width: "112px" }}
            >
              Cancel
            </Button>
            {notifyMailLoading ? (
              <button
                className="form-submit-btn btn btn-secondary"
                style={{ padding: "6px 16px", width: "110px" }}
              >
                <Spinner color="primary" />
              </button>
            ) : (
              <Button className="btn button" onClick={handleNotifyMail}>
                Yes, I’m Sure
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      </CardComponent>
    </Container>
  );
};

export default Dashboard;
