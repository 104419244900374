import axios from "axios";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { Col, Modal, Row, Spinner } from "react-bootstrap";
import { xamProSchema } from "../../SEP/Components/Schemas/LafargeScema";
import CustomInput from "./../../components/Common/InputComponent/CustomInput";
import successImage from "./successIcon.png";
import "./xampro.scss";
const initialValues = {
  name: "",
  email: "",
  subject: "Skillset test",
  message: "",
};

function XamProForm() {
  const [show] = useState(true);
  const [values, setValues] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);
  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  console.log(successMessage, "====>>>>>");
  const onSubmit = (values, e) => {
    console.log(values);
    setLoading(true);
    axios
      .post("https://activation-api.field-x.org/api/v1/ieltsx", values)
      .then(function (response) {
        setSuccessMessage(response.status);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };
  return (
    <div className="App">
      <Modal
        show={show}
        // onHide={handleClose}
        backdrop="static"
        centered
        className="modal-main-area-wrap"
      >
        {/* <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          {successMessage !== 200 ? (
            <div className="expertise-popup-modal-content-wrap">
              <h2>
                <b>Want to stay ahead ?</b>
              </h2>
              <h3>
                <b style={{ color: "#1b3f73" }}>Get 7+ Band score in IELTS</b>
              </h3>
              <h6 className="mb-4"> Get yourself enlisted!</h6>
              <Formik
                initialValues={values || initialValues}
                validationSchema={xamProSchema}
                onSubmit={onSubmit}
                enableReinitialize={true}
              >
                {({ values, errors, isSubmitting }) => (
                  <Form className="mt-4">
                    {/* {console.log('values', values, errors)} */}
                    <Row>
                      <Col md="12 mb-2">
                        <Field
                          name="name"
                          label={"Name"}
                          type="text"
                          component={CustomInput}
                          placeholder="Enter Name"
                        />
                      </Col>
                      <Col md="12 " style={{ position: "relative" }}>
                        <Field
                          className="start-phone-number"
                          label={"Email"}
                          name="email"
                          type="email"
                          component={CustomInput}
                          placeholder="Enter your email"
                        />
                      </Col>
                      <Col md="12 ">
                        <Field
                          name="subject"
                          label={"Subject"}
                          type="text"
                          component={CustomInput}
                          placeholder="Skillset test"
                        />
                      </Col>{" "}
                      <Col md="12 ">
                        <Field
                          name="message"
                          label={"Message"}
                          type="textarea"
                          component={CustomInput}
                          placeholder="Enter message"
                        />
                      </Col>
                    </Row>

                    <div className="mt-4">
                      {loading ? (
                        <div className="btn lafarge-first-stap-button w-100">
                          <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        </div>
                      ) : (
                        <button
                          className="btn lafarge-first-stap-button w-100"
                          type="submit"
                        >
                          Submit
                        </button>
                      )}
                    </div>
                  </Form>
                )}
              </Formik>
              {/* <Form className="contact-form-data-get" onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" placeholder="Type your name" />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>E-mail</Form.Label>
                <Form.Control type="email" placeholder="Type your e-mail" />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Subject</Form.Label>
                <Form.Control type="text" placeholder="Skillset test" />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Message</Form.Label>
                <Form.Control as="textarea" placeholder="Type your Message" />
              </Form.Group>

              <Button variant="primary" type="submit">
                Send Messege
              </Button>
            </Form> */}
            </div>
          ) : (
            <div className="xampro-submit-successfull-message">
              <img src={successImage} alt="" />
              <h2>Thanks for your information.</h2>
              <h4>To test your skills in IELTS, you can also check:</h4>
              <a href="https://learning.xampro.org/">
                <img
                  src="https://learning.xampro.org/static/media/logo.07b83739.svg"
                  alt=""
                />
              </a>
            </div>
          )}
        </Modal.Body>
      </Modal>
      <img
        style={{ width: "100%" }}
        src="https://images.unsplash.com/photo-1427504494785-3a9ca7044f45?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
        alt=""
      />
    </div>
  );
}

export default XamProForm;
