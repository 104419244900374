import * as Yup from "yup";

const createProductSchema = Yup.object({
  name: Yup.string().required("This field is required"),
  categoryId: Yup.string().required("This field is required"),
  order: Yup.number().required("This field is required"),
  file: Yup.string().when("imageUrl", {
    is: val => !val,
    then: Yup.string().required("This field is required")
  }),
  details: Yup.string(),
  imageUrl: Yup.string()
});

export { createProductSchema };

