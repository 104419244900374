import React from "react";
import CommercialMeetHeader from "../../Dashboard/Components/Others/CommercialMeetHeader";
import GridArea from "../../Dashboard/Components/Others/GridArea";
import MobileFooter from "../../Dashboard/Components/Others/MobileFooter";
import BkashIcon from "../../assets/image/others/bkash-logo.png";
import useCustomFavicon from "../../hooks/useCustomFavicon";
import { TabTitle } from "../../utils/GeneralFunctions";
import MobileContainer from "./MobileContainer";
// import "./others.scss";

export default function CommercialMeet() {
  TabTitle("Commercial Meet");
  useCustomFavicon(BkashIcon);

  return (
    <MobileContainer>
      <CommercialMeetHeader />
      <GridArea />
      <MobileFooter />
    </MobileContainer>
  );
}
