import { toast } from "react-toastify";
import { call, put, takeEvery } from "redux-saga/effects";
import { postData, updateData } from "../../Dashboard/helpers/api_helper";

import {
  getProject,
  getProjectCountFail,
  getProjectCountSuccess,
  getProjectFail,
  getProjectSuccess,
  sendAlertMailFail,
  sendAlertMailSuccess,
  sendDemoRequestFail,
  sendDemoRequestSuccess,
  sendNotifyMailFail,
  sendNotifyMailSuccess,
  storeDashboardData,
} from "./action.js";
import {
  ALERT_MAIL_REQUEST,
  GET_PROJECT,
  GET_TOTAL_PROJECT,
  NOTIFY_MAIL_REQUEST,
  SEND_DEMO_REQUEST,
} from "./actionTypes";

function* fetchProjectData({ payload: { authtoken, body, currentPage } }) {
  try {
    const url = `/users/view-demo-request?page=${
      !currentPage ? 0 : currentPage - 1
    }&limit=10&sort=0`;

    const response = yield call(postData, url, body, authtoken);

    yield put(getProjectSuccess(response));
  } catch (error) {
    yield put(getProjectFail(error));
  }
}
function* fetchProjectPaginationData({ payload: { authtoken, body } }) {
  try {
    const url = `/users/count-total-project`;

    const response = yield call(postData, url, body, authtoken);

    yield put(getProjectCountSuccess(response));
  } catch (error) {
    yield put(getProjectCountFail(error));
  }
}
function* sendDemoRequestMail({
  payload: { authtoken, values, body, currentPage },
}) {
  try {
    const url = `/users/update-subject-user`;

    const response = yield call(updateData, url, values, authtoken);

    yield put(sendDemoRequestSuccess(response));
    yield put(storeDashboardData("demoRequestLoading", false));
    yield put(getProject(authtoken, body, currentPage));
  } catch (error) {
    yield put(sendDemoRequestFail(error));
  }
}
function* sendAlertMail({ payload: { authtoken, data, body, currentPage } }) {
  try {
    const url = `/users/repeat-email/${data}`;

    const response = yield call(updateData, url, {}, authtoken);

    yield put(sendAlertMailSuccess(response));
    yield put(storeDashboardData("alertMailLoading", false));

    yield put(getProject(authtoken, body, currentPage));
    toast("Email Repeated successfully!", {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  } catch (error) {
    yield put(sendAlertMailFail(error));
  }
}
function* sendNotifyMail({ payload: { authtoken, data, body, currentPage } }) {
  try {
    const url = `/users/notify-mail/${data}/13-11-2022/8`;

    const response = yield call(updateData, url, {}, authtoken);

    yield put(sendNotifyMailSuccess(response));
    yield put(storeDashboardData("notifyMailLoading", false));
    yield put(getProject(authtoken, body, currentPage));
    toast("Email Repeated successfully!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  } catch (error) {
    yield put(sendNotifyMailFail(error));
  }
}

function* DashboardSaga() {
  yield takeEvery(GET_PROJECT, fetchProjectData);
  yield takeEvery(GET_TOTAL_PROJECT, fetchProjectPaginationData);
  yield takeEvery(SEND_DEMO_REQUEST, sendDemoRequestMail);
  yield takeEvery(ALERT_MAIL_REQUEST, sendAlertMail);
  yield takeEvery(NOTIFY_MAIL_REQUEST, sendNotifyMail);
}

export default DashboardSaga;
