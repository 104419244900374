import React from "react";

export default function RoomCheckIcon() {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M7.07307 3C5.6577 3 4.42667 3.96976 4.09539 5.34571L3.32823 8.53213C3.46571 8.51097 3.60654 8.5 3.74994 8.5H7.75026C9.26917 8.5 10.5005 9.73122 10.5005 11.25V18H18.4481C20.745 18 22.4373 15.8521 21.8997 13.6191L19.9078 5.34571C19.5766 3.96976 18.3455 3 16.9302 3H7.07307ZM10.4893 20.5C10.4967 20.4177 10.5005 20.3343 10.5005 20.25V19H17.2506C17.6648 19 18.0006 19.3358 18.0006 19.75C18.0006 20.1642 17.6648 20.5 17.2506 20.5H10.4893ZM5.7503 14.5C6.44072 14.5 7.00041 13.9404 7.00041 13.25C7.00041 12.5596 6.44072 12 5.7503 12C5.05989 12 4.5002 12.5596 4.5002 13.25C4.5002 13.9404 5.05989 14.5 5.7503 14.5ZM2 11.25C2 10.2835 2.78357 9.5 3.75014 9.5H7.75047C8.71704 9.5 9.50061 10.2835 9.50061 11.25V20.25C9.50061 21.2165 8.71704 22 7.75047 22H3.75014C2.78357 22 2 21.2165 2 20.25V11.25ZM3.75014 11C3.61206 11 3.50012 11.1119 3.50012 11.25V20.25C3.50012 20.3881 3.61206 20.5 3.75014 20.5H7.75047C7.88855 20.5 8.00049 20.3881 8.00049 20.25V11.25C8.00049 11.1119 7.88855 11 7.75047 11H3.75014Z"
          fill="#E2136E"
        />
      </svg>
    </div>
  );
}
