import {
  GET_REQUEST_LIST,
  GET_REQUEST_LIST_FAILED,
  GET_REQUEST_LIST_SUCCESS,
} from "./actionTypes";

export const getRequestList = ({ authtoken, body }) => {
  return {
    type: GET_REQUEST_LIST,
    payload: { authtoken, body },
  };
};

export const getRequestListSuccess = (data) => ({
  type: GET_REQUEST_LIST_SUCCESS,
  payload: { data },
});
export const getRequestListFail = (error) => ({
  type: GET_REQUEST_LIST_FAILED,
  payload: error,
});

///
