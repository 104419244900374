import { ccbSalesWheel } from "./actionType";

const initialState = {
  activeWheel: "",
};
const getCcbSctiveWheel = (state = initialState, action) => {
  switch (action.type) {
    case ccbSalesWheel:
      return { activeWheel: action.payload };
    default:
      return state;
  }
};
export default getCcbSctiveWheel;
