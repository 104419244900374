import { FieldArray, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { stepTwoSchema } from "../../SEP/Components/Schemas/LafargeScema";
import RadioButtons from "../../components/Common/InputComponent/RadioButtons";

const initialValues = {
  questions: [
    {
      question: "The objectives of the training were met",
      answer: "",
      questionformat: "radio",
      isActive: true,
      isDone: false,
    },
    {
      question: "The presenter was engaging",
      answer: "",
      questionformat: "radio",
      isActive: false,
      isDone: false,
    },
    {
      question: "The presentation materials were relevant",
      answer: "",
      questionformat: "radio",
      isActive: false,
      isDone: false,
    },
    {
      question: "The content of the course was organised and easy to follow",
      answer: "",
      questionformat: "radio",
      isActive: false,
      isDone: false,
    },
    {
      question:
        "The trainers were well prepared and able to answer any questions",
      answer: "",
      questionformat: "radio",
      isActive: false,
      isDone: false,
    },
    {
      question: "The course length was appropriate",
      answer: "",
      questionformat: "radio",
      isActive: false,
      isDone: false,
    },
    {
      question:
        "The pace of the course was appropriate to the content and attendees",
      answer: "",
      questionformat: "radio",
      isActive: false,
      isDone: false,
    },
    {
      question: "The exercises/role play were helpful and relevant",
      answer: "",
      questionformat: "radio",
      isActive: false,
      isDone: false,
    },
    {
      question: "The venue was appropriate for the event",
      answer: "",
      questionformat: "radio",
      isActive: false,
      isDone: false,
    },
  ],
};

const ElevateFeedbackForm = ({ handleSignup, handleData, valueData }) => {
  const [values, setValues] = useState(null);

  useEffect(() => {
    if (valueData?.questions?.length) {
      const oldData = { ...values };
      oldData.questions = valueData?.questions;
      setValues(oldData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueData?.questions]);

  const onSubmit = (values) => {
    if (values?.questions?.length) {
      handleData({
        questions: values,
      });
      handleSignup();
    }
  };

  return (
    <React.Fragment>
      <Row>
        <Col md="10" className="offset-md-1 p-5">
          <Formik
            initialValues={values || initialValues}
            validationSchema={stepTwoSchema}
            onSubmit={onSubmit}
            enableReinitialize={true}
          >
            {({ values, setFieldValue }) => (
              <Form className="mt-4">
                {/* {console.log('values', values, errors)} */}
                <FieldArray
                  name="questions"
                  render={() => (
                    <Row>
                      {values?.questions?.map((value, index) => (
                        <Col md="12" key={index}>
                          <div
                            className={
                              "mb-2 position-relative ques-label-round " +
                              (value?.isActive && !value.isDone ? "active" : "")
                            }
                          >
                            <RadioButtons
                              name={`questions.${index}.answer`}
                              label={value.question}
                              index={index}
                              mainClass={
                                value?.isActive && !value.isDone ? "active" : ""
                              }
                              className="ques-options-wrap"
                              onClick={(e) => {
                                if (index !== values?.questions?.length - 1) {
                                  setFieldValue(
                                    `questions.${index + 1}.isActive`,
                                    true
                                  );
                                }
                                setFieldValue(
                                  `questions.${index}.isDone`,
                                  true
                                );
                              }}
                              // disabled={!value.isActive}
                              options={[
                                { value: "2", message: "Strongly Agree" },
                                { value: "1", message: "Agree" },
                                {
                                  value: "0",
                                  message: "Not relevant to this event",
                                },
                                { value: "-1", message: "Disagree" },
                                { value: "-2", message: "Strongly Disagree" },
                              ]}
                            />
                          </div>
                        </Col>
                      ))}
                    </Row>
                  )}
                />
                <div className="mt-4 d-flex justify-content-center">
                  <button
                    className="btn lafarge-first-stap-button"
                    type="submit"
                    style={{ padding: "10px 50px" }}
                  >
                    {"Next"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ElevateFeedbackForm;
