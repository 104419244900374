import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import CustomInput from "../../../../components/Common/InputComponent/CustomInput";
import { getCategoryLoaded } from "../../../../Redux/Centro/Category/action";
import {
  addProductLoad,
  getSingleProductLoaded,
} from "../../../../Redux/Centro/Product/action";
import DashboardTitle from "../../components/DashboardTitle";
import { createProductSchema } from "../../schemas/createProductSchema";

const initialValue = {
  name: "",
  categoryId: "",
  order: "",
  details: "",
  file: "",
  files: {},
  imageUrl: "",
};

export default function AddProduct({ edit }) {
  const [values, setValues] = useState(null);
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { categoriesList, singleProduct } = useSelector((state) => ({
    categoryLoading: state?.categories?.loading,
    categoryError: state?.categories?.error,
    categoriesList: state?.categories?.categories?.categories,
    categoriesData: state?.categories?.categories,
    singleProduct: state?.products?.singleProduct?.category || {},
  }));

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    const formData = new FormData();
    if (values?.files?.name) {
      formData.append("photo", values.files);
    }
    formData.append("name", values.name);
    formData.append("categoryId", values.categoryId);
    formData.append("order", values.order);
    formData.append("details", values.details);

    // for (let obj of formData.entries()) {
    //   console.log(obj);
    // }
    // setSubmitting(false);

    dispatch(
      addProductLoad({
        data: formData,
        payload: { setSubmitting, resetForm, id: id || null, history },
      })
    );
  };

  useEffect(() => {
    dispatch(getCategoryLoaded({ payload: { page: 0, limit: 0 } }));
  }, [dispatch]);

  useEffect(() => {
    if (id) {
      dispatch(getSingleProductLoaded({ payload: { id } }));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (id && Object.keys(singleProduct).length) {
      const obj = {};
      obj.name = singleProduct?.name || "";
      obj.categoryId = singleProduct?.categoryId || "";
      obj.order = singleProduct?.order || "";
      obj.details = singleProduct?.details || "";
      obj.imageUrl = singleProduct?.image || "";
      // const imageName = singleProduct?.image?.split("/");
      // obj.file = imageName?.[imageName?.length - 1];
      obj.file = "";
      obj.files = {};

      setValues(obj);
    }
  }, [
    id,
    singleProduct,
    // singleProduct?.image,
  ]);

  // console.log(singleProduct);

  return (
    <section className="add_product_section px-4 my-5 py-4">
      <Container fluid>
        {/* <BreadcrumbDashboard leftTitle="Add Products" /> */}
        <DashboardTitle title={edit ? "Edit Product" : "Add Products"} />
        <Row>
          <Col md={6}>
            <Card className="p-4">
              <Formik
                initialValues={values || initialValue}
                validationSchema={createProductSchema}
                onSubmit={onSubmit}
                enableReinitialize={true}
              >
                {({ values, errors, isSubmitting, setFieldValue }) => (
                  <Form>
                    {/* {console.log({ values , errors })} */}
                    <Row>
                      <Col md="12 mb-2">
                        <Field
                          name="name"
                          label="Product Name"
                          type="text"
                          component={CustomInput}
                          placeholder="Product Name"
                          isRequired
                        />
                      </Col>
                      <Col md="12 mb-2">
                        <Field
                          name="order"
                          label="Order"
                          type="number"
                          component={CustomInput}
                          placeholder="Order"
                          isRequired
                        />
                      </Col>
                      <Col md="12 mb-2">
                        <Field
                          name="categoryId"
                          label={"Product Categories"}
                          type="select"
                          component={CustomInput}
                          placeholder="Select"
                          isRequired
                        >
                          <option value="">Select</option>
                          {categoriesList?.map((category) => (
                            <option value={category?._id}>
                              {category?.name}
                            </option>
                          ))}
                        </Field>
                      </Col>
                      <Col md="12 mb-2">
                        <Field
                          name="file"
                          type="file"
                          label="Product Image"
                          component={CustomInput}
                          placeholder="Type Details"
                          onChangeCallback={(data) => {
                            const file = data?.target?.files;
                            if (file?.length) {
                              setFieldValue("files", file?.[0]);
                            }
                          }}
                        />
                        {id && (
                          <img
                            src={
                              values?.files.name
                                ? URL.createObjectURL(values?.files)
                                : values?.imageUrl
                            }
                            style={{ width: "100px " }}
                            alt=""
                          />
                        )}
                      </Col>
                      <Col md="12 mb-2">
                        <Field
                          name="details"
                          label="Details"
                          rows={3}
                          type="textarea"
                          component={CustomInput}
                          placeholder="Type Details"
                        />
                      </Col>
                    </Row>

                    <div className="mt-4 d-flex justify-content-start gap-2">
                      <button
                        className="btn create-btn"
                        disabled={isSubmitting}
                        type="submit"
                      >
                        {isSubmitting
                          ? "Submitting..."
                          : edit
                          ? "Update"
                          : "Publish"}{" "}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
