import React from "react";
import EventHeaderBanner from "../../../assets/image/event-header.png";

export default function EventHeader() {
  return (
    <div
      className="event-header"
      style={{ backgroundImage: `url(${EventHeaderBanner})` }}
    >
      {/* <div className="mobile-wrapper">
        <h2>Bkash Commercial Meet 2024</h2>
      </div> */}
    </div>
  );
}
