import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BreadcrumbItem } from "reactstrap";
import "../styles/dashboardTitle.scss";

export default function DashboardTitle({
  title,
  description,
  breadcrumb,
  buttonText,
  buttonClass,
  buttonStyle,
  buttonClick,
  isRoundedBtn,
}) {
  return (
    <div className="d-flex justify-content-between align-items-center gap-2">
      <div className="dashboard_title py-3">
        <h3>{title}</h3>
        {description && <p className="mb-0">{description}</p>}
        {breadcrumb?.length > 0
          ? breadcrumb?.map((item, key) => (
              <BreadcrumbItem key={key} active={key + 1 === breadcrumb?.length}>
                <Link to={item.link}>{item.title}</Link>
              </BreadcrumbItem>
            ))
          : null}
      </div>
      {buttonText && (
        <div>
          <Button
            className={buttonClass}
            style={buttonStyle}
            onClick={buttonClick}
          >
            {buttonText}
          </Button>
        </div>
      )}
    </div>
  );
}
