import { CLEAR_DATA, STORE_DATA } from "./actionTypes";

const initialState = {
  step: 1,
  quesItem: {},
  stepOne: {},
  stepTwo: [],
  stepThree: [],
  stepFour: [],
};

const lafarge = (state = initialState, action) => {
  switch (action.type) {
    case STORE_DATA:
      return {
        ...state,
        step: state.step + 1,
        [action.payload.name]: action.payload.data,
      };
    case CLEAR_DATA:
      return {
        step: 1,
        quesItem: {},
        stepOne: {},
        stepTwo: [],
        stepThree: [],
      };
    default:
      return state;
  }
};

export default lafarge;
