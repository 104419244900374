import React from "react";

export default function EventIcon() {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M15.591 2.53032C14.7123 1.65164 13.2877 1.65164 12.409 2.53032L2.53035 12.409C1.65167 13.2877 1.65167 14.7123 2.53035 15.591L3.70189 16.7625C4.21258 17.2732 4.92945 17.1818 5.36261 16.9243C5.54862 16.8137 5.76564 16.75 5.99983 16.75C6.69019 16.75 7.24983 17.3097 7.24983 18C7.24983 18.2342 7.18614 18.4513 7.07558 18.6373C6.81811 19.0704 6.72667 19.7873 7.23736 20.298L8.40903 21.4697C9.28771 22.3483 10.7123 22.3483 11.591 21.4697L21.4697 11.591C22.3484 10.7123 22.3484 9.28768 21.4697 8.409L20.2981 7.23739C19.7873 6.72664 19.0704 6.81816 18.6372 7.07571C18.4512 7.18632 18.2341 7.25004 17.9998 7.25004C17.3095 7.25004 16.7498 6.6904 16.7498 6.00004C16.7498 5.7658 16.8136 5.54872 16.9242 5.36268C17.1817 4.92952 17.2732 4.21255 16.7625 3.7018L15.591 2.53032Z"
          fill="#E2136E"
        />
      </svg>
    </div>
  );
}
