import {
  ADD_PRODUCT_LOADED,
  DELETE_PRODUCT_LOADED,
  GET_PRODUCT,
  GET_PRODUCT_ERROR,
  GET_PRODUCT_LOADED,
  GET_SINGLE_PRODUCT,
  GET_SINGLE_PRODUCT_ERROR,
  GET_SINGLE_PRODUCT_LOADED,
} from "./actionTypes";

// get product loaded
const getProductLoaded = ({ payload }) => {
  return {
    type: GET_PRODUCT_LOADED,
    payload,
  };
};

// get all products
const getAllProducts = (data) => {
  return {
    type: GET_PRODUCT,
    payload: data,
  };
};

// get product error
const getProductFail = (err) => {
  return {
    type: GET_PRODUCT_ERROR,
    payload: err,
  };
};

// add product loaded
const addProductLoad = (data) => {
  return {
    type: ADD_PRODUCT_LOADED,
    payload: data,
  };
};

// delete product loaded
const deleteProductLoad = (data) => {
  return {
    type: DELETE_PRODUCT_LOADED,
    payload: data,
  };
};

// get single product loaded
const getSingleProductLoaded = ({ payload }) => {
  return {
    type: GET_SINGLE_PRODUCT_LOADED,
    payload,
  };
};

// get single product
const getSingleProducts = (data) => {
  return {
    type: GET_SINGLE_PRODUCT,
    payload: data,
  };
};

// get single product error
const getSingleProductFail = (err) => {
  return {
    type: GET_SINGLE_PRODUCT_ERROR,
    payload: err,
  };
};

export {
  getProductLoaded,
  getAllProducts,
  getProductFail,
  addProductLoad,
  deleteProductLoad,
  getSingleProductLoaded,
  getSingleProducts,
  getSingleProductFail,
};
