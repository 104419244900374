/* eslint-disable jsx-a11y/role-supports-aria-props */
// MetisMenu
import MetisMenu from "metismenujs";
import React, { useEffect, useRef } from "react";
import { Link, withRouter } from "react-router-dom";
// //Import Scrollbar
import SimpleBar from "simplebar-react";
import icon1 from "../icon/dashboard.svg";

const SidebarContent = (props) => {
  const ref = useRef();

  useEffect(() => {
    const pathName = props.location.pathname;
    const initMenu = () => {
      const splitMain = pathName?.split("/");
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        const spitPath = items[i].pathname.split("/");
        if (
          pathName === items[i].pathname ||
          splitMain?.[2] === spitPath?.[2]
        ) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location.pathname]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{"Menu"} </li>
            <li>
              <Link to="/admin/dashboard" className="">
                <i>
                  <img src={icon1} alt="icon" />
                </i>
                <span>{"Dashboard"}</span>
              </Link>
            </li>
            {/* <li>
              <Link to="/centro/dashboard" className="">
                <i>
                  <img src={icon1} alt="icon" />
                </i>
                <span>{"Centro dashboard"}</span>
              </Link>
            </li> */}
            <li>
              <Link to="/centro/landing-page" className="">
                <i>
                  <i className="bx bx-blanket"></i>
                </i>
                <span>{"Landing Page"}</span>
              </Link>
            </li>
            <li>
              <Link to="/centro/categories" className="">
                <i>
                  <i className="bx bx-duplicate"></i>
                </i>
                <span>{"Categories"}</span>
              </Link>
            </li>
            <li>
              <Link to="/centro/products" className="">
                <i>
                  <i className="bx bxs-shopping-bags"></i>
                </i>
                <span>{"Products"}</span>
              </Link>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

export default withRouter(SidebarContent);
