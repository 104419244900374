import moment from "moment";
import React from "react";
import * as XLSX from "xlsx/xlsx.mjs";

function ExcelDownloader({ requestList }) {
  console.log(requestList);
  const downloadExcelFile = () => {
    const data = requestList;

    const columnsToRemove = ["__v"];

    // Remove the specified columns from the data array
    const modifiedData = data.map((row) => {
      const modifiedRow = { ...row };
      columnsToRemove.forEach((column) => {
        delete modifiedRow[column];
      });
      return modifiedRow;
    });
    //======================
    const formattedData = modifiedData.map((row) => ({
      ...row,
      createdAt: moment(row.createdAt).format("YYYY-MM-DD"),
      updatedAt: moment(row.updatedAt).format("YYYY-MM-DD"),
    }));
    //======================
    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    worksheet["!cols"] = [
      { width: 40 },
      { width: 15 },
      { width: 15 },
      { width: 20 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 35 },
      { width: 40 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 30 },
      { width: 30 },
    ];
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const excelBlob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const downloadLink = URL.createObjectURL(excelBlob);
    const a = document.createElement("a");
    a.href = downloadLink;
    a.download = `${moment().format("LL")}_Demo_Request.xlsx`;
    a.click();

    // Delay revoking the object URL to support older browsers
    setTimeout(() => {
      URL.revokeObjectURL(downloadLink);
    }, 100);
  };

  return (
    <div>
      <button
        style={{
          border: "none",
          padding: "12px 30px",
          display: "inline-block",
          marginBottom: "10px",
          background: "#ddd",
          borderRadius: "5px",
          fontWeight: "bold",
        }}
        onClick={downloadExcelFile}
      >
        Download Excel
      </button>
    </div>
  );
}

export default ExcelDownloader;
