import { FieldArray, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import RadioButtons from "../../../../../components/Common/InputComponent/RadioButtons";
import { storeData } from "../../../../../Redux/Lafarge/actions";
import { stepTwoSchema } from "../../../Schemas/LafargeScema";

const initialValues = {
  questions: [],
};

const StepTwo = ({ questions, stepName, nextStepNumber }) => {
  const dispatch = useDispatch();
  const [values, setValues] = useState(null);
  const { step } = useSelector((state) => ({
    step: state.lafarge.step,
  }));
  useEffect(() => {
    window?.scrollTo({ top: step > 2 ? 700 : 0, left: 0, behavior: "smooth" });
    if (questions?.length) {
      const oldData = { ...values };
      oldData.questions = questions;
      setValues(oldData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);
  const onSubmit = (values, { resetForm }) => {
    dispatch(storeData(stepName, values?.questions));
    resetForm();
    // dispatch(storeData("step", nextStepNumber));
  };
  console.log(questions);
  return (
    <React.Fragment>
      <Row>
        <Col md="12">
          <Formik
            initialValues={values || initialValues}
            validationSchema={stepTwoSchema}
            onSubmit={onSubmit}
            enableReinitialize={true}
          >
            {({ values, setFieldValue }) => (
              <Form className="mt-4">
                {console.log("values", values)}
                <FieldArray
                  name="questions"
                  render={() => (
                    <Row>
                      {values?.questions?.map((value, index) => (
                        <Col md="12" key={index}>
                          <div
                            className={
                              "mb-2 position-relative ques-label-round " +
                              (value?.isActive && !value.isDone ? "active" : "")
                            }
                          >
                            <RadioButtons
                              name={`questions.${index}.answer`}
                              label={value.question}
                              index={index}
                              mainClass={
                                value?.isActive && !value.isDone ? "active" : ""
                              }
                              className="ques-options-wrap"
                              onClick={(e) => {
                                if (index !== values?.questions?.length - 1) {
                                  setFieldValue(
                                    `questions.${index + 1}.isActive`,
                                    true
                                  );
                                }
                                setFieldValue(
                                  `questions.${index}.isDone`,
                                  true
                                );
                              }}
                              // disabled={!value.isActive}
                              options={[
                                { value: "2", message: "Strongly Agree" },
                                { value: "1", message: "Agree" },
                                {
                                  value: "0",
                                  message: "Not relevant to this event",
                                },
                                { value: "-1", message: "Disagree" },
                                { value: "-2", message: "Strongly Disagree" },
                              ]}
                            />
                          </div>
                        </Col>
                      ))}
                    </Row>
                  )}
                />
                <div className="mt-4 d-flex justify-content-center">
                  <button
                    className="btn lafarge-first-stap-button"
                    type="submit"
                    style={{ padding: "10px 50px" }}
                  >
                    {"Next"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default StepTwo;
