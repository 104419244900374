import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "../../assets/css/contact.css";
import Footer from "../../Common/Footer";
import Header from "../../Common/Header";
import { TabTitle } from "../../utils/GeneralFunctions";
import CallToAction1 from "../Calibrax/products/CallToAction1";
import ContactForm from "./ContactForm";
import OfficeLocation from "./OfficeLocation";
import "./style.scss";
const DemoReqContact = () => {
  TabTitle("Connect");
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div className="App">
      <Header />
      <ContactForm />
      <OfficeLocation />
      <CallToAction1 />
      <Footer />
    </div>
  );
};

export default DemoReqContact;
