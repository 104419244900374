import * as Yup from "yup";

const demoRequestSchema = Yup.object({
  name: Yup.string().required("Please enter name"),
  // subject: Yup.string().required("Please enter subject"),
  phoneNumber: Yup.string()
    .required("Please enter phone number")
    .min(10, "Please remove the initial zero.")
    .max(10, "Maximum 10 digits allowed"),
  officeEmail: Yup.string().email("Invalid email").required("Required"),
  position: Yup.string().required("Please enter position"),
  address: Yup.string().required("Please enter office address"),
});

export { demoRequestSchema };
