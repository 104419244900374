import React, { useState } from "react";
import CustomModal from "../../Dashboard/Components/Layout/CustomModal";
import BackPage from "../../Dashboard/Components/Others/BackPage";
import EventDetailsCard from "../../Dashboard/Components/Others/EventDetailsCard";
import ImageHeader from "../../Dashboard/Components/Others/ImageHeader";
import MobileFooter from "../../Dashboard/Components/Others/MobileFooter";
import ViewPDF from "../../Dashboard/Components/Others/ViewPDF";
// import TravelPDF from "../../assets/image/Flight list Master File Final.pdf";
import ClearIcon from "../../assets/image/close-icon.png";
import TravelPDF from "../../assets/image/others/Flight Details.pdf";
import TravelIcon from "../../assets/image/others/TravelIcon";
import BkashIcon from "../../assets/image/others/bkash-logo.png";
import BG from "../../assets/image/others/flight.png";
import useCustomFavicon from "../../hooks/useCustomFavicon";
import { TabTitle } from "../../utils/GeneralFunctions";
import MobileContainer from "./MobileContainer";

export default function TravelDetailsPage() {
  TabTitle("Flight Details");
  useCustomFavicon(BkashIcon);
  const [showPDF, setShowPDF] = useState(false);

  const toggle = () => {
    setShowPDF(false);
  };

  return (
    <MobileContainer>
      {showPDF ? (
        <CustomModal open={true} toggle={toggle} size={"sm"} modalBody="">
          <div className="d-flex justify-content-end mb-2 position-relative">
            <button
              className="btn p-2 d-flex justify-content-center align-items-center"
              style={{ borderRadius: "50%", width: 30, height: 30 }}
              onClick={toggle}
            >
              <img style={{ height: 12 }} src={ClearIcon} alt="" />
            </button>
          </div>
          <div className="contact-modal-pdf">
            <ViewPDF pdfFile={TravelPDF} toggle={toggle} />
          </div>
        </CustomModal>
      ) : null}

      <BackPage
        title="Flight Details"
        backLink={`/commercial-meet-2024`}
        downloadDocument={TravelPDF}
      />
      <ImageHeader bg={BG} />
      <EventDetailsCard
        title="Flight Details"
        icon={TravelIcon}
        handleCardClick={() => setShowPDF(true)}
      />
      <div className="position-absolute bottom-0 w-100">
        <MobileFooter />
      </div>
    </MobileContainer>
  );
}
