import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { TabTitle } from "../../../../utils/GeneralFunctions";
import "../../../sep.scss";
import EsQFooter from "./EsQFooter";
import "./lafarge.scss";
import LafargeHeader from "./LafargeHeader";
import LafargeHero from "./LafargeHero";
import {
  EBITADa,
  GoToMarket,
  marketKnowledge,
  RecoveryManagement,
  Salesmanship,
  SalesProcess,
  WinningHabits,
} from "./LafargeQues/ques1";
import StepOne from "./LafargeQues/StepOne";
import StepThree from "./LafargeQues/StepThree";
import StepTwo from "./LafargeQues/StepTwo";
import PageItemText from "./PageItemText";

const Lafarge2 = () => {
  TabTitle("MAAC Lafarge");
  const { step } = useSelector((state) => ({
    step: state.lafarge.step,
  }));

  // console.log(`step`, step);

  const loadData = () => {
    switch (step) {
      case 1:
        return <StepOne />;
      case 2:
        return (
          <StepTwo
            questions={marketKnowledge}
            stepName={"stepTwo"}
            nextStepNumber={3}
          />
        );
      case 3:
        return (
          <StepTwo
            questions={Salesmanship}
            stepName={"stepThree"}
            nextStepNumber={4}
          />
        );
      case 4:
        return (
          <StepTwo
            questions={EBITADa}
            stepName={"stepFour"}
            nextStepNumber={5}
          />
        );
      case 5:
        return (
          <StepTwo
            questions={WinningHabits}
            stepName={"stepFive"}
            nextStepNumber={6}
          />
        );
      case 6:
        return (
          <StepTwo
            questions={GoToMarket}
            stepName={"stepSix"}
            nextStepNumber={7}
          />
        );
      case 7:
        return (
          <StepTwo
            questions={SalesProcess}
            stepName={"stepSeven"}
            nextStepNumber={8}
          />
        );
      case 8:
        return (
          <StepTwo
            questions={RecoveryManagement}
            stepName={"stepEight"}
            nextStepNumber={9}
          />
        );
      case 9:
        return <StepThree />;
      default:
        return "No data";
    }
  };
  return (
    <div className="tas lafarge-main-wrap lafarge-2">
      <LafargeHeader />
      {step !== 1 && <LafargeHero />}

      <Container>
        <div className="show-lafarge-data">
          <div className={`progress-barr lafargenavbar--2 ${step}`}>
            <ul>
              {step === 2 ? (
                <div
                  className={`progress-single-item ${
                    step === 2 && "progress-single-1"
                  }`}
                >
                  <PageItemText />
                </div>
              ) : step === 3 ? (
                <>
                  <div
                    className={`progress-single-item ${
                      step === 3 && "progress-single-2"
                    }`}
                  >
                    <PageItemText />
                  </div>
                </>
              ) : step === 4 ? (
                <div
                  className={`progress-single-item ${
                    step === 4 && "progress-single-3"
                  }`}
                >
                  <PageItemText />
                </div>
              ) : step === 5 ? (
                <div
                  className={`progress-single-item ${
                    step === 5 && "progress-single-4"
                  }`}
                >
                  <PageItemText />
                </div>
              ) : step === 6 ? (
                <div
                  className={`progress-single-item ${
                    step === 6 && "progress-single-5"
                  }`}
                >
                  <PageItemText />
                </div>
              ) : step === 7 ? (
                <div
                  className={`progress-single-item ${
                    step === 7 && "progress-single-6"
                  }`}
                >
                  <PageItemText />
                </div>
              ) : step === 8 ? (
                <div
                  className={`progress-single-item ${
                    step === 8 && "progress-single-7"
                  }`}
                >
                  <PageItemText />
                </div>
              ) : step === 9 ? (
                <div
                  className={`progress-single-item ${
                    step === 9 && "progress-single-8"
                  }`}
                >
                  <PageItemText />
                </div>
              ) : null}
            </ul>
          </div>
          {loadData()}
        </div>
      </Container>

      <EsQFooter />
      {/* LafargeHero */}
    </div>
  );
};

export default Lafarge2;
