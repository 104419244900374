import {
  GET_REQUEST_LIST,
  GET_REQUEST_LIST_FAILED,
  GET_REQUEST_LIST_SUCCESS,
} from "./actionTypes";
const INIT_STATE = {
  isLoading: false,
  requestList: [],
};
const requestListReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_REQUEST_LIST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_REQUEST_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        requestList: action.payload,
      };
    case GET_REQUEST_LIST_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};
export default requestListReducer;
