import * as Yup from "yup";

const SendRequestSchema = Yup.object({
  // link: Yup.string().required("Please enter Link"),
  // appLink: Yup.string(),

  subjectId: Yup.string().required("Please enter subject"),
});

export { SendRequestSchema };
