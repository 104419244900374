import React from "react";
import { Card, CardBody } from "reactstrap";

const CardComponent = ({ className, bodyClass, style, children, ...rest }) => {
  return (
    <React.Fragment>
      <Card
        className={className + " border-0"}
        {...rest}
        style={{ borderRadius: "unset" }}
      >
        <CardBody className={bodyClass} style={style}>
          {children}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default CardComponent;
