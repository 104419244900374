import React, { useState } from "react";
import CustomModal from "../../Dashboard/Components/Layout/CustomModal";
import BackPage from "../../Dashboard/Components/Others/BackPage";
import EventDetailsCard from "../../Dashboard/Components/Others/EventDetailsCard";
import ImageHeader from "../../Dashboard/Components/Others/ImageHeader";
import MobileFooter from "../../Dashboard/Components/Others/MobileFooter";
import ViewPDF from "../../Dashboard/Components/Others/ViewPDF";
import ClearIcon from "../../assets/image/close-icon.png";
import ROOM_PDF from "../../assets/image/others/Room Allocation Commercial Meet 2024.pdf";
import RoomCheckIcon from "../../assets/image/others/RoomCheckIcon";
import BkashIcon from "../../assets/image/others/bkash-logo.png";
import BG from "../../assets/image/others/room.png";
import useCustomFavicon from "../../hooks/useCustomFavicon";
import { TabTitle } from "../../utils/GeneralFunctions";
import MobileContainer from "./MobileContainer";

export default function RoomCheckPage() {
  TabTitle("Room Details");
  useCustomFavicon(BkashIcon);
  const [showPDF, setShowPDF] = useState(false);

  const toggle = () => {
    setShowPDF(false);
  };

  return (
    <MobileContainer>
      {showPDF ? (
        <CustomModal
          open={true}
          toggle={toggle}
          size={"sm"}
          modalBody="contact-modal"
        >
          <div className="d-flex justify-content-end mb-2 position-relative">
            <button
              className="btn p-2 d-flex justify-content-center align-items-center"
              style={{ borderRadius: "50%", width: 30, height: 30 }}
              onClick={toggle}
            >
              <img style={{ height: 12 }} src={ClearIcon} alt="" />
            </button>
          </div>
          <div className="contact-modal-pdf">
            <ViewPDF pdfFile={ROOM_PDF} toggle={toggle} />
          </div>
        </CustomModal>
      ) : null}

      <BackPage
        title="Room Details"
        backLink={`/commercial-meet-2024`}
        downloadDocument={ROOM_PDF}
      />
      <ImageHeader bg={BG} />
      <EventDetailsCard
        title="Room Details"
        icon={RoomCheckIcon}
        handleCardClick={() => setShowPDF(true)}
      />
      <div className="position-absolute bottom-0 w-100">
        <MobileFooter />
      </div>
    </MobileContainer>
  );
}
