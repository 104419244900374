import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import CardComponent from "../../../../../components/Common/CardComponent";
import CustomInput from "../../../../../components/Common/InputComponent/CustomInput";
import { storeData } from "../../../../../Redux/Lafarge/actions";
import { stepOneSchema } from "../../../Schemas/LafargeScema";

const initialValues = {
  name: "",
  phoneNumber: "",
  designation: "",
};

const StepOne = () => {
  const dispatch = useDispatch();
  const [values, setValues] = useState(null);
  const [errorMsg, setErrorMsg] = useState("");
  const { stepOne } = useSelector((state) => ({
    step: state.lafarge.step,
    stepOne: state.lafarge.stepOne,
  }));
  //   console.log(`const{step}=useSelector ~ step`, step);

  useEffect(() => {
    if (stepOne?.name) {
      const oldData = { ...values };
      oldData.name = stepOne?.name;
      oldData.phoneNumber = stepOne?.phoneNumber;
      oldData.designation = stepOne?.designation;
      setValues(oldData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepOne]);

  const onSubmit = (values) => {
    let phoneNumberStr = values?.phoneNumber.toString();
    if (phoneNumberStr.length > 9) {
      let splitval = phoneNumberStr.split("");
      if (
        (splitval[0] == 1 && splitval[1] == 7) ||
        (splitval[0] == 1 && splitval[1] == 8) ||
        (splitval[0] == 1 && splitval[1] == 9) ||
        (splitval[0] == 1 && splitval[1] == 4) ||
        (splitval[0] == 1 && splitval[1] == 6) ||
        (splitval[0] == 1 && splitval[1] == 5) ||
        (splitval[0] == 1 && splitval[1] == 3)
      ) {
        dispatch(storeData("stepOne", values));
        dispatch(storeData("step", 2));
      } else {
        setErrorMsg("Phone number is not valid");
      }
    } else {
      setErrorMsg("Phone number's length must be equal to 10 digits");
    }
  };
  return (
    <React.Fragment>
      <div className="lafarge-form-main-wrap">
        <Row className="">
          <Col md="7" className="mx-auto ">
            <CardComponent className={"lafarge-inner-form-wrap"}>
              <Col md="6" className="offset-md-3">
                <Formik
                  initialValues={values || initialValues}
                  validationSchema={stepOneSchema}
                  onSubmit={onSubmit}
                  enableReinitialize={true}
                >
                  {({ values, errors, isSubmitting }) => (
                    <Form className="mt-4">
                      {/* {console.log('values', values, errors)} */}
                      <Row>
                        <Col md="12 mb-2">
                          <Field
                            name="name"
                            label={"Name"}
                            type="text"
                            component={CustomInput}
                            placeholder="Enter Name"
                          />
                        </Col>
                        <Col md="12 " style={{ position: "relative" }}>
                          <span className="start-phone-number">+88</span>
                          <Field
                            maxLength={5}
                            className="start-phone-number"
                            label={"Phone Number"}
                            name="phoneNumber"
                            type="number"
                            component={CustomInput}
                            placeholder="Enter phone number"
                          />
                          {errorMsg ? (
                            <span style={{ color: "red", fontSize: "11px" }}>
                              {errorMsg}
                            </span>
                          ) : (
                            ""
                          )}
                        </Col>
                        <Col md="12 ">
                          <Field
                            name="designation"
                            label={"University"}
                            type="text"
                            component={CustomInput}
                            placeholder="Enter university"
                          />
                        </Col>
                      </Row>

                      <div className="mt-4">
                        <button
                          className="btn lafarge-first-stap-button w-100"
                          type="submit"
                        >
                          {"Go to feedback form "}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </Col>
            </CardComponent>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default StepOne;
