import React from "react";

export default function EventDetailsCard({
  title,
  icon: Icon,
  handleCardClick,
}) {
  return (
    <div className="mobile-wrapper mt-3" style={{ marginBottom: 60 }}>
      <div className="item-card" key={title} onClick={handleCardClick}>
        <Icon />
        <h5>{title}</h5>
      </div>
    </div>
  );
}
