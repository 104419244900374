import {
  ALERT_MAIL_FAIELD,
  ALERT_MAIL_REQUEST,
  ALERT_MAIL_SUCCSESS,
  GET_PROJECT,
  GET_PROJECT_FAIELD,
  GET_PROJECT_SUCCSESS,
  GET_TOTAL_PROJECT,
  GET_TOTAL_PROJECT_FAIELD,
  GET_TOTAL_PROJECT_SUCCSESS,
  NOTIFY_MAIL_FAIELD,
  NOTIFY_MAIL_REQUEST,
  NOTIFY_MAIL_SUCCSESS,
  SEND_DEMO_FAIELD,
  SEND_DEMO_REQUEST,
  SEND_DEMO_SUCCSESS,
  STORE_DASHBOARD_DATA,
} from "./actionTypes";

export const getProject = (authtoken, body, currentPage) => ({
  type: GET_PROJECT,
  payload: { authtoken, body, currentPage },
});

export const getProjectSuccess = (data) => ({
  type: GET_PROJECT_SUCCSESS,
  payload: { data },
});
export const getProjectFail = (error) => ({
  type: GET_PROJECT_FAIELD,
  payload: error,
});

///
export const getProjectCount = (authtoken, body) => ({
  type: GET_TOTAL_PROJECT,
  payload: { authtoken, body },
});

export const getProjectCountSuccess = (data) => ({
  type: GET_TOTAL_PROJECT_SUCCSESS,
  payload: { data },
});
export const getProjectCountFail = (error) => ({
  type: GET_TOTAL_PROJECT_FAIELD,
  payload: error,
});

//=======================================
export const storeDashboardData = (name, data) => ({
  type: STORE_DASHBOARD_DATA,
  payload: { name, data },
});
//=======================================

export const sendDemoRequest = (authtoken, values, body, currentPage) => ({
  type: SEND_DEMO_REQUEST,
  payload: { authtoken, values, body, currentPage },
});

export const sendDemoRequestSuccess = (data) => ({
  type: SEND_DEMO_SUCCSESS,
  payload: { data },
});
export const sendDemoRequestFail = (error) => ({
  type: SEND_DEMO_FAIELD,
  payload: error,
});
//=======================================

export const sendAlertMail = (authtoken, data, body, currentPage) => ({
  type: ALERT_MAIL_REQUEST,
  payload: { authtoken, data, body, currentPage },
});

export const sendAlertMailSuccess = (data) => ({
  type: ALERT_MAIL_SUCCSESS,
  payload: { data },
});
export const sendAlertMailFail = (error) => ({
  type: ALERT_MAIL_FAIELD,
  payload: error,
});
//=======================================

export const sendNotifyMail = (authtoken, data, body, currentPage) => ({
  type: NOTIFY_MAIL_REQUEST,
  payload: { authtoken, data, body, currentPage },
});

export const sendNotifyMailSuccess = (data) => ({
  type: NOTIFY_MAIL_SUCCSESS,
  payload: { data },
});
export const sendNotifyMailFail = (error) => ({
  type: NOTIFY_MAIL_FAIELD,
  payload: error,
});
