import { Field, FieldArray, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CustomInput from "../../../../../components/Common/InputComponent/CustomInput";
import RadioButtons from "../../../../../components/Common/InputComponent/RadioButtons";
import { post } from "../../../../../helpers/api_helper";
import { clearData } from "../../../../../Redux/Lafarge/actions";
import { stepTwoSchema } from "../../../Schemas/LafargeScema";

const initialValues = {
  questions: [
    {
      id: 1,
      question: "I was well oriented with the purpose of the program",
      answer: "",
      questionformat: "radio",
      isActive: true,
      isDone: false,
    },
    {
      id: 2,
      question: "I found the program engaging",
      answer: "",
      questionformat: "radio",
      isActive: false,
      isDone: false,
    },
    {
      id: 3,
      question: "I found the program helpful for myself and the club",
      answer: "",
      questionformat: "radio",
      isActive: false,
      isDone: false,
    },
    {
      id: 4,
      question: "I was adequately briefed about the upcoming activities",
      answer: "",
      questionformat: "radio",
      isActive: false,
      isDone: false,
    },
    {
      id: 5,
      question:
        "I found the environment within the office & program host to be friendly",
      answer: "",
      questionformat: "radio",
      isActive: false,
      isDone: false,
    },
    {
      id: 6,
      question:
        "I was clear about all the communications throughout the journey",
      answer: "",
      questionformat: "radio",
      isActive: false,
      isDone: false,
    },
    {
      question: "Additional comments",
      answer: "",
      questionformat: "textarea",
    },
  ],
};

const StepTwo = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [values, setValues] = useState(null);
  const { stepOne, stepTwo } = useSelector((state) => ({
    step: state.lafarge.step,

    stepOne: state.lafarge.stepOne,
    stepTwo: state.lafarge.stepTwo,
  }));

  useEffect(() => {
    if (stepTwo?.length) {
      const oldData = { ...values };
      oldData.questions = stepTwo;
      setValues(oldData);
    }
  }, [stepTwo, values]);

  const onSubmit = async (values, { setSubmitting }) => {
    const data = {
      ...stepOne,
      feedback: [...values?.questions],
    };

    try {
      const postData = await post("/laparge/new-survey", data);
      if (postData) {
        dispatch(clearData());
        history.push("/exampro/success");
      }
      setSubmitting(false);
    } catch (error) {
      console.log(error);
      setSubmitting(false);
    }
  };
  return (
    <React.Fragment>
      <Row>
        <Col md="10" className="offset-md-1">
          <Formik
            initialValues={values || initialValues}
            validationSchema={stepTwoSchema}
            onSubmit={onSubmit}
            enableReinitialize={true}
          >
            {({ values, setFieldValue }) => (
              <Form className="mt-4">
                <FieldArray
                  name="questions"
                  render={() => (
                    <Row>
                      {values?.questions?.map((value, index) => (
                        <Col md="12" key={index}>
                          {value.questionformat === "radio" ? (
                            <div
                              className={
                                "mb-2 position-relative ques-label-round " +
                                (value?.isActive && !value.isDone
                                  ? "active"
                                  : "")
                              }
                            >
                              <RadioButtons
                                name={`questions.${index}.answer`}
                                label={value.question}
                                index={index}
                                mainClass={
                                  value?.isActive && !value.isDone
                                    ? "active"
                                    : ""
                                }
                                className="ques-options-wrap"
                                onClick={(e) => {
                                  if (index !== values?.questions?.length - 1) {
                                    setFieldValue(
                                      `questions.${index + 1}.isActive`,
                                      true
                                    );
                                  }
                                  setFieldValue(
                                    `questions.${index}.isDone`,
                                    true
                                  );
                                }}
                                // disabled={!value.isActive}
                                options={[
                                  { value: "2", message: "Strongly Agree" },
                                  { value: "1", message: "Agree" },
                                  {
                                    value: "0",
                                    message: "Not relevant to this event",
                                  },
                                  { value: "-1", message: "Disagree" },
                                  { value: "-2", message: "Strongly Disagree" },
                                ]}
                              />
                            </div>
                          ) : (
                            <Field
                              name={`questions.${index}.answer`}
                              label={value?.question}
                              type="textarea"
                              rows="6"
                              component={CustomInput}
                            />
                          )}
                        </Col>
                      ))}
                    </Row>
                  )}
                />
                <div className="mt-4 d-flex justify-content-center">
                  <button
                    className="btn lafarge-first-stap-button"
                    type="submit"
                    style={{ padding: "10px 50px" }}
                  >
                    {"Submit"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default StepTwo;
