const initialState = {
  designGetClass: [],
};

const SvgItemChange = (state = initialState, action) => {
  // console.log(action.payload);

  switch (action.type) {
    case "DATA_CHANGE1":
      return {
        ...state,
        designGetClass: action.payload,
      };

    case "DATA_CHANGE2":
      return {
        ...state,
        designGetClass: action.payload,
      };
    case "DATA_CHANGE3":
      return {
        ...state,
        designGetClass: action.payload,
      };

    case "DATA_CHANGE4":
      return {
        ...state,
        designGetClass: action.payload,
      };

    case "DATA_CHANGE5":
      return {
        ...state,
        designGetClass: action.payload,
      };

    case "DATA_CHANGE6":
      return {
        ...state,
        designGetClass: action.payload,
      };

    case "DATA_CHANGE7":
      return {
        ...state,
        designGetClass: action.payload,
      };

    default:
      return state;
  }
};
export default SvgItemChange;
