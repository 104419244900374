import React from "react";

const PageItemText = () => {
  return (
    <>
      {" "}
      <li>
        Market Knowledge <i className="bx bx-chevron-right"></i>
      </li>{" "}
      <li>
        Salesmanship <i className="bx bx-chevron-right"></i>
      </li>
      <li>
        EBITADa in Sales <i className="bx bx-chevron-right"></i>
      </li>
      <li>
        Winning Habits <i className="bx bx-chevron-right"></i>
      </li>{" "}
      <li>
        Go-To-Market <i className="bx bx-chevron-right"></i>
      </li>{" "}
      <li>
        Sales process & Methodology <i className="bx bx-chevron-right"></i>
      </li>{" "}
      <li>
        Recovery Management <i className="bx bx-chevron-right"></i>
      </li>{" "}
      <li>General</li>
    </>
  );
};

export default PageItemText;
