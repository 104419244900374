import { call, put, takeEvery } from "redux-saga/effects";
import { toaster } from "../../../Dashboard/Components/Helpers/Custom/Toaster";
import { del, get, patch, post } from "../../../Dashboard/helpers/api_helper";
import { getAllCategories, getCategoryFail } from "./action";
import {
  ADD_CATEGORIES_LOADED,
  DELETE_CATEGORIES_LOADED,
  GET_CATEGORIES_LOADED,
} from "./actionTypes";

function* getCategories({ payload: { page = 0, limit = 0 } }) {
  try {
    const url = `/category/data?pageNo=${page}&limit=${limit}`;
    const response = yield call(get, url);

    yield put(getAllCategories(response));
  } catch (error) {
    yield put(getCategoryFail(error));
  }
}

function* addCategories({
  payload: {
    data,
    payload: { setSubmitting, resetForm, toggle, id },
  },
}) {
  try {
    const url = id ? `/category/${id}` : `/category`;
    const response = yield call(id ? patch : post, url, data);

    if (response) {
      toaster("success", `Category ${id ? "update" : "added"} successfully!`);
    }
    yield setSubmitting(false);
    yield resetForm(true);
    yield toggle(true);
  } catch (error) {
    const err =
      error?.response?.data?.message ||
      `Category ${id ? "update" : "added"} fail!`;
    toaster("error", err);
    yield setSubmitting(false);
  }
}

function* deleteCategories({
  payload: {
    id,
    payload: { toggle },
  },
}) {
  try {
    const url = `/category/${id}`;
    const response = yield call(del, url);

    if (response) {
      toaster("success", "Category delete successfully!");
    }
    yield toggle(true);
  } catch (error) {
    const err = error?.response?.data?.message || "Category delete fail!";
    toaster("error", err);
  }
}

function* categoriesSaga() {
  yield takeEvery(GET_CATEGORIES_LOADED, getCategories);
  yield takeEvery(ADD_CATEGORIES_LOADED, addCategories);
  yield takeEvery(DELETE_CATEGORIES_LOADED, deleteCategories);
}

export default categoriesSaga;
