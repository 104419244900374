import React, { useEffect } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  deleteProductLoad,
  getProductLoaded,
} from "../../../../Redux/Centro/Product/action";
import Loader from "../../../Components/Common/Loader";
import DashboardTitle from "../../components/DashboardTitle";
import "../../styles/products.scss";

import { useCallback } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Swal from "sweetalert2";
import PlaceholderImage from "../../../../assets/image/placeholder-image.jpg";
import DeleteIcon from "../../images/delete.svg";
import EditIcon from "../../images/edit.svg";

export default function Products() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { loading, productsList } = useSelector((state) => ({
    loading: state?.products?.loading,
    error: state?.products?.error,
    productsList: state?.products?.products?.products,
    productsData: state?.products?.products,
  }));

  const getProducts = useCallback(
    () => dispatch(getProductLoaded({ payload: { page: 0, limit: 0 } })),
    [dispatch]
  );

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  const toggle = (api) => {
    if (api) {
      getProducts();
    }
  };

  const handleDeleteProduct = (id) => {
    Swal.fire({
      title: "Are you sure to delete?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ca2d07",
      cancelButtonColor: "#ddd",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteProductLoad({ id, payload: { toggle } }));
      }
    });
  };

  return (
    <section className="px-4 my-5 py-4">
      <Container fluid>
        {/* <BreadcrumbDashboard leftTitle="Products" /> */}
        <DashboardTitle
          title="Products"
          buttonText="Add New Product"
          buttonClass="create-btn"
          buttonClick={() => history?.push("/centro/products/add-product")}
          description="You can see how many products sample are showing here"
        />

        {loading ? (
          <Card
            className="d-flex align-items-center justify-content-center w-100 h-100"
            style={{ minHeight: "200px" }}
          >
            <Loader />
          </Card>
        ) : productsList?.length > 0 ? (
          <Row>
            {productsList?.map((product) => (
              <Col md={3} key={product?._id}>
                <Card className="product_card">
                  <div className="position-relative">
                    <LazyLoadImage
                      style={{
                        objectFit: "cover",
                        width: "100%",
                        minHeight: "200px",
                        cursor: "pointer",
                      }}
                      wrapperClassName="w-100"
                      // height="100px"
                      // className="features_image"
                      // loading="Loading..."
                      alt={""}
                      effect="blur"
                      placeholderSrc={PlaceholderImage}
                      src={product?.image}
                    />
                    {/* <img src={product?.image} alt="" /> */}
                    <div className="action">
                      <div
                        style={{
                          width: "25px",
                          height: "25px",
                          backgroundColor: "#fff",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                          borderRadius: 50,
                          boxShadow: "0 0 5px #000",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          history.push(
                            `/centro/products/add-product/${product?._id}`
                          )
                        }
                      >
                        <img
                          src={EditIcon}
                          style={{
                            height: "16px",
                            width: "16px",
                          }}
                          alt=""
                        />
                      </div>
                      <div
                        style={{
                          width: "25px",
                          height: "25px",
                          backgroundColor: "#fff",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                          borderRadius: 50,
                          boxShadow: "0 0 5px #000",
                          cursor: "pointer",
                        }}
                        onClick={() => handleDeleteProduct(product?._id)}
                      >
                        <img
                          src={DeleteIcon}
                          style={{ height: "16px", width: "16px" }}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="card_content">
                    <div className="category mb-2">
                      {product?.categoryId?.name || "N/A"}
                    </div>
                    <div>
                      <h3>{product?.name}</h3>
                      <p className="mb-0">{product?.details}</p>
                    </div>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        ) : (
          <Card
            className="d-flex align-items-center justify-content-center w-100 text-center h-100"
            style={{ minHeight: "200px" }}
          >
            Product Not found
          </Card>
        )}
      </Container>
    </section>
  );
}
