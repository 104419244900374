import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import CustomInput from "../../../../../components/Common/InputComponent/CustomInput";
import RadioButtons from "../../../../../components/Common/InputComponent/RadioButtons";
import TextError from "../../../../../components/Common/InputComponent/TextError";
import { post } from "../../../../../helpers/api_helper";
import { clearData } from "../../../../../Redux/Lafarge/actions";

const initialValues = {
  questions: [
    {
      question: "The objectives of the training were met ",
      answer: "",
      questionformat: "radio",
      isActive: true,
      isDone: false,
    },
    {
      question: "The presenter was engaging ",
      answer: "",
      questionformat: "radio",
      isActive: true,
      isDone: false,
    },
    {
      question: "The training length was appropriate",
      answer: "",
      questionformat: "radio",
      isActive: true,
      isDone: false,
    },
    {
      question: "What was most useful? ",
      answer: "",
      questionformat: "textarea",
    },
    // {
    //   question: "What was least useful? ",
    //   answer: "",
    //   questionformat: "textarea",
    // },
    {
      question:
        "What else would you like to see included in this event? Are there any other topics that you would like to be offered training courses in? ",
      answer: "",
      questionformat: "textarea",
    },
    {
      question: "Would you recommend this course to other colleagues?",
      answer: "",
      questionformat: "textarea",
    },
    {
      question: "Any other comments?",
      answer: "",
      questionformat: "textarea",
    },
  ],
};

const StepThree = ({ history }) => {
  const dispatch = useDispatch();
  // const [values, setValues] = useState(null);
  const {
    stepThree,
    stepOne,
    stepTwo,
    stepFour,
    stepFive,
    stepSix,
    stepSeven,
    stepEight,
  } = useSelector((state) => ({
    step: state.lafarge.step,
    stepThree: state.lafarge.stepThree,
    stepOne: state.lafarge.stepOne,
    stepTwo: state.lafarge.stepTwo,
    stepFour: state.lafarge.stepFour,
    stepFive: state.lafarge.stepFive,
    stepSix: state.lafarge.stepSix,
    stepSeven: state.lafarge.stepSeven,
    stepEight: state.lafarge.stepEight,
  }));

  const onSubmit = async (values, { setSubmitting }) => {
    const data = {
      ...stepOne,
      feedback: [
        ...stepTwo,
        ...stepThree,
        ...stepFour,
        ...stepFive,
        ...stepSix,
        ...stepSeven,
        ...stepEight,
        ...values?.questions,
      ],
    };
    // console.log(`onSubmit ~ values`, data);
    try {
      const postData = await post("/laparge/lafargetwo", data);
      if (postData) {
        dispatch(clearData());

        history.push("/lafarge/success");
      }
      setSubmitting(false);
    } catch (error) {
      console.log(error);
      setSubmitting(false);
    }
  };

  return (
    <React.Fragment>
      <div className="lafarge-step-three-form-main-wrap">
        <Container>
          <Row>
            <Col md="12">
              <Formik
                initialValues={initialValues}
                // validationSchema={stepTwoSchema}
                onSubmit={onSubmit}
                enableReinitialize={true}
              >
                {({ values, errors, isSubmitting, setFieldValue }) => (
                  <Form className="mt-4">
                    {/* {console.log("values", values, errors)} */}
                    <FieldArray
                      name="questions"
                      render={() => (
                        <Row>
                          {values?.questions?.map((value, index) =>
                            value.questionformat === "textarea" ? (
                              <Col md="12 mb-4" key={index}>
                                <Field
                                  name={`questions.${index}.answer`}
                                  label={value?.question}
                                  type="textarea"
                                  rows="6"
                                  component={CustomInput}
                                />
                                <ErrorMessage
                                  name={`questions.${index}.answer`}
                                  component={TextError}
                                />
                              </Col>
                            ) : (
                              <Col md="12" key={index}>
                                <div
                                  className={
                                    "mb-2 position-relative ques-label-round " +
                                    (value?.isActive && !value.isDone
                                      ? "active"
                                      : "")
                                  }
                                >
                                  <RadioButtons
                                    name={`questions.${index}.answer`}
                                    label={value.question}
                                    index={index}
                                    mainClass={
                                      value?.isActive && !value.isDone
                                        ? "active"
                                        : ""
                                    }
                                    className="ques-options-wrap"
                                    onClick={(e) => {
                                      if (
                                        index !==
                                        values?.questions?.length - 1
                                      ) {
                                        setFieldValue(
                                          `questions.${index + 1}.isActive`,
                                          true
                                        );
                                      }
                                      setFieldValue(
                                        `questions.${index}.isDone`,
                                        true
                                      );
                                    }}
                                    // disabled={!value.isActive}
                                    options={[
                                      { value: "2", message: "Strongly Agree" },
                                      { value: "1", message: "Agree" },
                                      {
                                        value: "0",
                                        message: "Not relevant to this event",
                                      },
                                      { value: "-1", message: "Disagree" },
                                      {
                                        value: "-2",
                                        message: "Strongly Disagree",
                                      },
                                    ]}
                                  />
                                </div>
                              </Col>
                            )
                          )}
                        </Row>
                      )}
                    />
                    <div className="mt-4 d-flex justify-content-center">
                      <button
                        className="btn lafarge-first-stap-button"
                        type="submit"
                        style={{ padding: "10px 50px" }}
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? "Submitting..." : "Submit"}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(StepThree);
