import React, { useEffect, useRef, useState } from "react";
import { Container, Spinner } from "react-bootstrap";
import Confetti from "react-confetti";
import { useDispatch, useSelector } from "react-redux";
import useWindowSize from "react-use/lib/useWindowSize";
import { Col, Row } from "reactstrap";

import {
  getUserList,
  getWinnerList,
  resetWinner,
  selectWinner,
  storeRaffleData,
} from "../../store/actions";
import { TabTitle } from "../../utils/GeneralFunctions";
import bkashBg from "./img/bkash-bg.svg";
import background from "./img/bkash-raffle.png";
import BkashLogo from "./img/BkashLogo";
import "./raffle-draw.scss";
const RaffleDraw = () => {
  TabTitle("GROW BEYOND");
  const raffleDrawName = useRef();
  const [raffleShowData, setRaffleData] = useState(false);
  const [winnerLoading, setWinnerLoading] = useState(false);
  const [resetBtn, setResetBtn] = useState(false);
  const { width, height } = useWindowSize();
  const dispatch = useDispatch();
  const [winnerClb, setWinnerClb] = useState(false);
  const {
    userList,
    findWinner,
    winnerList,
    adding,
    raffleData,
    findWinnerLoading,
    winnerListLoading,
  } = useSelector((state) => ({
    userList: state.RaffleReducer.userList,
    findWinner: state.RaffleReducer.findWinner,
    winnerList: state.RaffleReducer.winnerList,
    raffleData: state.RaffleReducer.raffleData,
    adding: state.RaffleReducer.adding,
    findWinnerLoading: state.RaffleReducer.findWinnerLoading,
    winnerListLoading: state.RaffleReducer.winnerListLoading,
  }));

  useEffect(() => {
    dispatch(getUserList());
  }, []);
  useEffect(() => {
    dispatch(getWinnerList());
  }, []);
  // Choose Winner button
  const handleShow = () => {
    setRaffleData(true);
    setWinnerLoading(true);
    document.getElementById("winner-name").innerHTML = "";
    document.getElementById("winner-designation").innerHTML = "";
    let interval = setInterval(function () {
      document.getElementById("met").innerHTML = generateRandom();
    }, 100);

    setTimeout(function () {
      clearInterval(interval);
      dispatch(selectWinner());
      document.getElementById("met").innerHTML = "";
      setWinnerLoading(false);
    }, 8000);

    setWinnerClb(true);
  };
  function generateRandom() {
    const randomIndex = Math.floor(Math.random() * userList?.length);
    const item = userList?.data?.[randomIndex]?.Name;
    return item;
  }
  const resetWinnerData = () => {
    setResetBtn(true);
    dispatch(storeRaffleData("raffleData", []));
  };
  const startAgainData = () => {
    dispatch(resetWinner());
  };
  // slider area
  console.log("winnerList", winnerList);
  return (
    <div className="raffle-draw-page">
      {winnerClb ? <Confetti width={width} height={height} /> : ""}
      <div
        className="raffle-draw-main-wrap"
        style={{ backgroundImage: `url(${background})` }}
      >
        <div>
          <Container>
            <div className="raffle-draw-inner-area-wrap">
              <Row className="mb-3">
                <Col lg="12 mb-3">
                  <div className="raffle-draw-right-part-area">
                    {winnerLoading ? (
                      ""
                    ) : (
                      <button
                        onClick={handleShow}
                        disabled={
                          winnerList?.values?.totallength === 16 ||
                          (winnerList?.values?.totallength === 13 &&
                            resetBtn === false)
                        }
                        style={{
                          opacity:
                            winnerList?.values?.totallength === 16 ||
                            (winnerList?.values?.totallength === 13 &&
                              resetBtn === false)
                              ? 0.5
                              : "",
                        }}
                      >
                        Choose winner
                      </button>
                    )}

                    {/* {raffleShowData && ( */}
                    <div
                      style={{
                        visibility: raffleShowData ? "visible" : "hidden",
                      }}
                    >
                      <div className="choose-winner-single-info-wrap">
                        <div className="choose-winner-single-box-wrap">
                          <img
                            className="bkash-card-bg-image"
                            src={bkashBg}
                            alt=""
                          />
                          <button
                            onClick={() => {
                              setRaffleData(false);
                              setWinnerClb(false);
                            }}
                            className="close-button"
                          >
                            <i class="bx bx-x"></i>
                          </button>
                          <div className="show-choose-raffle-content">
                            <p
                              id="met"
                              ref={raffleDrawName}
                              style={{ color: "red", fontWeight: "700" }}
                            ></p>

                            {winnerListLoading ? (
                              <Spinner animation="border" variant="dark" />
                            ) : (
                              <>
                                <p
                                  id="winner-name"
                                  style={{
                                    marginBottom: "5px",
                                    fontWeight: "700",
                                  }}
                                >
                                  {findWinner?.message?.Name}
                                </p>
                                <p
                                  id="winner-designation"
                                  style={{ fontSize: "18px", color: "#999" }}
                                >
                                  {findWinner?.message?.Designation}
                                </p>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* )} */}
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
          <div className="raffle-winner-list-bottom">
            <Container style={{ position: "relative" }}>
              <div className="winner-list-position-area-list">
                <div className="raffle-draw-winner-list-area-wrap">
                  <Row>
                    {raffleData?.length > 0
                      ? raffleData?.map((data, idx) => (
                          <Col
                            lg={winnerList?.values?.totallength > 13 ? 4 : 3}
                            className="mb-3"
                            key={idx}
                          >
                            <div className="single-raffle-winner">
                              <div className="winner-company-brand-logo">
                                <BkashLogo />
                              </div>
                              <h3>{data?.Name}</h3>
                              <p>{data?.EmployeeID}</p>
                              <p style={{ color: "#ff6200" }}>
                                {data?.Designation}
                              </p>
                              <p>{data?.Department}</p>
                              <p>{data?.prizeName}</p>
                              <div className="bkash-winner-serial-no-bg">
                                <h1>{data?.serial}</h1>
                              </div>
                            </div>
                          </Col>
                        ))
                      : ""}
                  </Row>
                  {/* {winnerList?.values?.length > 4 ? (
                    <Slider {...settings}>
                      {winnerList?.values?.length > 0 &&
                        winnerList?.values?.map((data, idx) => (
                          <div key={idx}>
                            <div className="single-raffle-winner">
                              <div className="winner-company-brand-logo">
                                <BkashLogo />
                              </div>
                              <h3>{data?.Name}</h3>
                              <p>{data?.EmployeeID}</p>
                              <p style={{ color: "#ff6200" }}>
                                {data?.Designation}
                              </p>
                              <p>{data?.Department}</p>
                              <p>{data?.prizeName}</p>
                              <div className="bkash-winner-serial-no-bg">
                                <h1>{data?.serial}</h1>
                              </div>
                            </div>
                          </div>
                        ))}
                    </Slider>
                  ) : (
                    ""
                  )} */}
                  {winnerList?.values?.totallength === 13 &&
                  resetBtn === false ? (
                    <Row className="mt-3">
                      <div className="reffle-reset-btn">
                        <button onClick={resetWinnerData} disabled={adding}>
                          {adding ? "Submitting" : "Reset Winner"}
                        </button>
                      </div>
                    </Row>
                  ) : (
                    ""
                  )}
                  {winnerList?.values?.totallength === 16 ? (
                    <Row className="mt-3">
                      <div className="reffle-reset-btn">
                        <button onClick={startAgainData} disabled={adding}>
                          {adding ? "Submitting" : "Start Again"}
                        </button>
                      </div>
                    </Row>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </Container>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RaffleDraw;
