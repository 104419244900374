import { call, put, takeEvery } from "redux-saga/effects";
import { toaster } from "../../../Dashboard/Components/Helpers/Custom/Toaster";
import { del, get, patch, post } from "../../../Dashboard/helpers/api_helper";
import {
  getAllProducts,
  getProductFail,
  getSingleProductFail,
  getSingleProducts,
} from "./action";
import {
  ADD_PRODUCT_LOADED,
  DELETE_PRODUCT_LOADED,
  GET_PRODUCT_LOADED,
  GET_SINGLE_PRODUCT_LOADED,
} from "./actionTypes";

function* getProducts({ payload: { page = 0, limit = 0 } }) {
  try {
    const url = `/product/data?pageNo=${page}&limit=${limit}`;
    const response = yield call(get, url);

    yield put(getAllProducts(response));
  } catch (error) {
    yield put(getProductFail(error));
  }
}

function* addProducts({
  payload: {
    data,
    payload: { setSubmitting, resetForm, id, history },
  },
}) {
  try {
    const url = id ? `/product/${id}` : `/product`;
    const response = yield call(id ? patch : post, url, data);

    if (response) {
      toaster("success", `Product ${id ? "update" : "added"} successfully!`);
    }

    yield setSubmitting(false);
    yield resetForm(true);
    history.push("/centro/products");
    // yield toggle(true);
  } catch (error) {
    console.log(error);
    const err =
      error?.response?.data?.message ||
      `Product ${id ? "update" : "added"} fail!`;
    toaster("error", err);
    yield setSubmitting(false);
  }
}

function* deleteProduct({
  payload: {
    id,
    payload: { toggle },
  },
}) {
  try {
    const url = `/product/${id}`;
    const response = yield call(del, url);

    if (response) {
      toaster("success", "Product delete successfully!");
    }
    yield toggle(true);
  } catch (error) {
    const err = error?.response?.data?.message || "Product delete fail!";
    toaster("error", err);
  }
}

function* getSingleProduct({ payload: { id } }) {
  try {
    const url = `/product/${id}`;
    const response = yield call(get, url);

    yield put(getSingleProducts(response));
  } catch (error) {
    yield put(getSingleProductFail(error));
  }
}

function* productsSaga() {
  yield takeEvery(GET_PRODUCT_LOADED, getProducts);
  yield takeEvery(ADD_PRODUCT_LOADED, addProducts);
  yield takeEvery(DELETE_PRODUCT_LOADED, deleteProduct);
  yield takeEvery(GET_SINGLE_PRODUCT_LOADED, getSingleProduct);
}

export default productsSaga;
