import { Field, FieldArray, Form, Formik } from "formik";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useHistory, withRouter } from "react-router-dom";
import CustomInput from "../../components/Common/InputComponent/CustomInput";
import { post } from "../../helpers/api_helper";

const initialValues = {
  questions: [
    {
      question: "1. What was the most useful?",
      answer: "",
      questionformat: "textarea",
    },
    // {
    //   question: "2. What was least useful?",
    //   answer: "",
    //   questionformat: "textarea",
    // },
    {
      question:
        "2. What else would you like to see included in this event?   Are there any other topics that you would like to be offered training courses in?",
      answer: "",
      questionformat: "textarea",
    },
    {
      question: "3. Would you recommend this course to colleagues?",
      answer: "",
      questionformat: "textarea",
    },
    {
      question: "4. Any other comments?",
      answer: "",
      questionformat: "textarea",
    },
  ],
};

const ElevateFeedbackForm2 = ({ handleSignup, handleData, valueData }) => {
  const history = useHistory();
  // const [values, setValues] = useState(null);

  // useEffect(() => {
  //   if (valueData?.feedback?.length) {
  //     const oldData = { ...values };
  //     oldData.questions = valueData?.feedback;
  //     setValues(oldData);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [valueData?.feedback]);

  const onSubmit = async (values, { setSubmitting }) => {
    const data = {
      ...valueData?.signup,
      feedback: [...valueData?.questions?.questions, ...values?.questions],
    };

    // console.log(data);

    try {
      const postData = await post("/elevate/elevate-feedback", data, {
        baseURL: "https://staging-api.hrxbd.org/api/v1",
      });

      if (postData) {
        history.push("/feedback-elevate/success");
      }

      setSubmitting(false);
    } catch (error) {
      console.log(error);
      setSubmitting(false);
    }
  };

  return (
    <React.Fragment>
      <div className="lafarge-step-three-form-main-wrap">
        <Container>
          <Row>
            <Col md="10" className="offset-md-1">
              <Formik
                initialValues={initialValues}
                // validationSchema={stepTwoSchema}
                onSubmit={onSubmit}
                enableReinitialize={true}
              >
                {({ values, errors, isSubmitting }) => (
                  <Form className="mt-4">
                    {/* {console.log("values", values, errors)} */}
                    <FieldArray
                      name="questions"
                      render={() => (
                        <Row>
                          {values?.questions?.map((value, index) => (
                            <Col md="12 mb-4" key={index}>
                              <Field
                                name={`questions.${index}.answer`}
                                label={value?.question}
                                type="textarea"
                                rows="6"
                                component={CustomInput}
                              />
                              {/* <ErrorMessage
                                name={`questions.${index}.answer`}
                                component={TextError}
                              /> */}
                            </Col>
                          ))}
                        </Row>
                      )}
                    />
                    <div className="mt-4 d-flex justify-content-center">
                      <button
                        className="btn lafarge-first-stap-button"
                        type="submit"
                        style={{ padding: "10px 50px" }}
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? "Submitting..." : "Submit"}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ElevateFeedbackForm2);
