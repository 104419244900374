import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import EventIcon from "../../../assets/image/others/EventIcon";
import LocationIcon from "../../../assets/image/others/LocationIcon";
import MobileIcon from "../../../assets/image/others/MobileIcon";
import RightArrowIcon from "../../../assets/image/others/RightArrowIcon";
import RoomCheckIcon from "../../../assets/image/others/RoomCheckIcon";
import TravelIcon from "../../../assets/image/others/TravelIcon";
import AlertIcon from "../../../assets/image/others/alertIcon.png";
import CustomModal from "../Layout/CustomModal";

export default function GridArea() {
  const history = useHistory();
  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen(false);
  };

  return (
    <div className="grid-area mobile-wrapper">
      {open ? (
        <CustomModal
          open={true}
          toggle={toggle}
          size={"sm"}
          modalBody="contact-modal"
        >
          <div className="alert-icon">
            <img src={AlertIcon} alt="" />
          </div>

          {/* <h2>Facing any trouble?</h2>
          <p>Please call the emergency number below</p> */}
          <div className="contact-number mt-3">
            <div>
              <MobileIcon />
            </div>
            <div>
              {/* <h5>01772-754340</h5> */}
              <h5 style={{ fontFamily: "monospace" }}>+8801677697620</h5>
              {/* <h5>02-0255 2335</h5> */}
            </div>
          </div>
        </CustomModal>
      ) : null}

      <div className="event-grid">
        {gridList?.map(({ title, icon: Icon, link }) => (
          <div
            className="item-card"
            key={title}
            onClick={() => history.push(`/commercial-meet-2024/${link}`)}
          >
            <Icon />
            <h5>{title}</h5>
          </div>
        ))}
      </div>

      <div className="content-area">
        <button onClick={() => setOpen(true)}>
          <span>Further Query</span>
          <RightArrowIcon />
        </button>
      </div>
    </div>
  );
}

const gridList = [
  {
    title: "Itinerary",
    icon: EventIcon,
    link: "itinerary",
  },
  {
    title: "Flight Details",
    icon: TravelIcon,
    link: "flight-details",
  },
  {
    title: "Room Details",
    icon: RoomCheckIcon,
    link: "room-details",
  },
  {
    title: "Location",
    icon: LocationIcon,
    link: "location",
  },
];
