import { CLEAR_DATA, STORE_DATA } from './actionTypes';

export const storeData = (name, data) => ({
  type: STORE_DATA,
  payload: { name, data },
});

export const clearData = () => ({
  type: CLEAR_DATA,
});
