import {
  ADD_CATEGORIES_LOADED,
  DELETE_CATEGORIES_LOADED,
  GET_CATEGORIES,
  GET_CATEGORIES_ERROR,
  GET_CATEGORIES_LOADED,
} from "./actionTypes";

// get category loaded
const getCategoryLoaded = ({ payload }) => {
  return {
    type: GET_CATEGORIES_LOADED,
    payload,
  };
};

// get all categories
const getAllCategories = (data) => {
  return {
    type: GET_CATEGORIES,
    payload: data,
  };
};

// get category error
const getCategoryFail = (err) => {
  return {
    type: GET_CATEGORIES_ERROR,
    payload: err,
  };
};

// add category loaded
const addCategoryLoaded = (data) => {
  return {
    type: ADD_CATEGORIES_LOADED,
    payload: data,
  };
};

// delete category loaded
const deleteCategoryLoaded = (id) => {
  return {
    type: DELETE_CATEGORIES_LOADED,
    payload: id,
  };
};

export {
  getCategoryLoaded,
  getAllCategories,
  getCategoryFail,
  addCategoryLoaded,
  deleteCategoryLoaded,
};
