import React from "react";
// import mapLocation from "../../assets/image/MapSection.png";
const OfficeLocation = () => {
  return (
    <div className="container">
      <div className="office-location-wrap">
        {/* <img src={mapLocation} alt="" /> */}
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3650.837265969571!2d90.41331171534519!3d23.788808593224047!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c758a13d9e4d%3A0x5eabe643e36fed1b!2sMarket%20Access%20Analytics%20and%20Consulting!5e0!3m2!1sen!2sbd!4v1641211481116!5m2!1sen!2sbd"
          allowFullScreen
          loading="lazy"
        />
      </div>
    </div>
  );
};

export default OfficeLocation;
