import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Table } from "react-bootstrap";
import { DownloadTableExcel } from "react-export-table-to-excel";
import "./excelDownload.scss";
const ExcelDownloadBtn = () => {
  const baseURL = "https://ddc-api.salesx-staging.xyz/api/v1/laparge";
  const [dataDownload, setDataDownload] = useState([]);
  useEffect(() => {
    axios.get(baseURL).then((response) => {
      setDataDownload(response?.data?.data);
    });
  }, []);
  const tableRef = useRef(null);
  console.log(dataDownload?.laparge);
  return (
    <div className="">
      <>
        <Table bordered striped ref={tableRef}>
          <tbody>
            {dataDownload?.laparge?.map((data, idx) => {
              return (
                <>
                  <tr>
                    <th>ID</th>
                    <th>
                      <h4>Name</h4>
                    </th>
                    <th>designation</th>
                    <th>Phone number</th>
                    <th>Date</th>
                  </tr>
                  <tr>
                    <th>{idx + 1}</th>
                    <th>
                      <h4>{data.name}</h4>
                    </th>
                    <th>{data.designation}</th>
                    <th>0{data.phoneNumber}</th>
                    <th>{data.createdAt.slice(0, 10)}</th>
                    <>
                      {data?.feedback?.map((feedback, idx) => (
                        <tr key={idx}>
                          <td>
                            <b>Ques: {idx + 1}</b>
                            <p>{feedback.question}</p>
                          </td>
                          <td>
                            <b>Answer: </b>
                            {feedback.answer}
                          </td>
                        </tr>
                      ))}
                    </>
                  </tr>
                </>
              );
            })}
          </tbody>
        </Table>
        <br />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <DownloadTableExcel
            filename="users table"
            sheet="users"
            currentTableRef={tableRef.current}
          >
            <button
              style={{
                border: "none",
                padding: "12px 30px",
                display: "inline-block",
                marginBottom: "30px",
                background: "#ddd",
                borderRadius: "5px",
                fontWeight: "bold",
              }}
            >
              {" "}
              Export excel{" "}
            </button>
          </DownloadTableExcel>
        </div>
      </>
    </div>
  );
};

export default ExcelDownloadBtn;
