import React from "react";
import MAACLogo from "../../../assets/image/logo-main.png";

export default function MobileFooter() {
  return (
    <div className="mobile-footer">
      <span>Powered by</span>
      <img src={MAACLogo} alt="" />
    </div>
  );
}
