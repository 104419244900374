import React, { useCallback, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import {
  deleteCategoryLoaded,
  getCategoryLoaded,
} from "../../../../Redux/Centro/Category/action";
import CardComponent from "../../../Components/Common/CardComponent";
import Loader from "../../../Components/Common/Loader";
import CustomTable from "../../../Components/Layout/CustomTable";
import CreateCategory from "../../components/CreateCategory";
import DashboardTitle from "../../components/DashboardTitle";
import DeleteIcon from "../../images/delete.svg";
import EditIcon from "../../images/edit.svg";
import EyeIcon from "../../images/eye.svg";
import "../../styles/categories.scss";

// table header
const tableHead = [
  "SL No.",
  // "Categories Image",
  "Category Name",
  "Order",
  "Details",
  //   "Status",
  "Action",
];

export default function Categories() {
  const [open, setOpen] = useState({
    open: false,
    type: "",
    data: {},
  });
  const dispatch = useDispatch();
  const { loading, categoriesList } = useSelector((state) => ({
    loading: state?.categories?.loading,
    error: state?.categories?.error,
    categoriesList: state?.categories?.categories?.categories,
    categoriesData: state?.categories?.categories,
  }));

  //   console.log(categoriesList);

  const getCategories = useCallback(
    () => dispatch(getCategoryLoaded({ payload: { page: 0, limit: 0 } })),
    [dispatch]
  );

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  const toggle = (api) => {
    if (api) {
      getCategories();
    }

    setOpen({
      open: false,
      type: "",
      data: {},
    });
  };

  const handleDeleteCategory = (id) => {
    Swal.fire({
      title: "Are you sure to delete?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ca2d07",
      cancelButtonColor: "#ddd",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteCategoryLoaded({ id, payload: { toggle } }));
      }
    });
  };

  return (
    <section className="px-4 my-5 py-4">
      <Container fluid>
        {/* <BreadcrumbDashboard leftTitle="Products" /> */}
        <DashboardTitle
          title="Categories"
          buttonText="Create Categories"
          buttonClass="dark-btn"
          buttonStyle={{ borderRadius: "5px" }}
          buttonClick={() =>
            setOpen({
              open: true,
              type: "Add",
              data: {},
            })
          }
        />

        {open?.open ? (
          <CreateCategory
            open={open?.open}
            toggle={toggle}
            edit={open?.type === "Edit"}
            view={open?.type === "View"}
            add={open?.type === "Add"}
            data={open?.data || {}}
          />
        ) : null}

        <CardComponent bodyClass="p-0">
          <CustomTable
            tableHead={tableHead}
            tHeadClass="bg-table-primary"
            // tbodyStyle={{
            //   height: leaveApplicationList?.totalLength <= 3 ? "400px" : "",
            // }}
          >
            {loading ? (
              <tr>
                <td colSpan={6}>
                  <Loader />
                </td>
              </tr>
            ) : categoriesList?.length > 0 ? (
              categoriesList?.map((data, idx) => (
                <tr key={data?.id}>
                  <td
                    style={{
                      paddingLeft: "25px",
                    }}
                  >
                    {idx + 1}
                  </td>
                  {/* <td>
                    {data?.image ? (
                      <img
                        src={data?.image}
                        alt=""
                        style={{
                          width: "50px",
                          height: "50px",
                          objectFit: "cover",
                        }}
                      />
                    ) : (
                      "N/A"
                    )}
                  </td> */}
                  <td>{data?.name}</td>
                  <td>{data?.order}</td>
                  <td>{data?.details}</td>
                  {/* <td>
                    <button
                      className={
                        data?.status === "Complete"
                          ? "complete_btn status"
                          : data?.status === "Active"
                          ? "active_btn status"
                          : data?.status === "Pending"
                          ? "pending_btn status"
                          : null
                      }
                    >
                      {data?.status}
                    </button>
                  </td> */}
                  <td>
                    <div className="d-flex gap-2 align-items-center">
                      <img
                        src={EyeIcon}
                        style={{ height: "16px", cursor: "pointer" }}
                        alt=""
                        onClick={() =>
                          setOpen({
                            open: true,
                            type: "View",
                            data,
                          })
                        }
                      />
                      <img
                        src={EditIcon}
                        style={{ height: "16px", cursor: "pointer" }}
                        alt=""
                        onClick={() =>
                          setOpen({
                            open: true,
                            type: "Edit",
                            data,
                          })
                        }
                      />
                      <img
                        src={DeleteIcon}
                        style={{ height: "16px", cursor: "pointer" }}
                        alt=""
                        onClick={() => handleDeleteCategory(data?._id)}
                      />
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6} className="text-center">
                  <span className="text-center">No data Available</span>
                </td>
              </tr>
            )}
          </CustomTable>
        </CardComponent>
      </Container>
    </section>
  );
}
