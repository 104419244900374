export const GET_PROJECT = "GET_PROJECT";
export const GET_PROJECT_SUCCSESS = "GET_PROJECT_SUCCSESS";
export const GET_PROJECT_FAIELD = "GET_PROJECT_FAIELD";
export const STORE_DASHBOARD_DATA = "STORE_DASHBOARD_DATA";
//================================================= Get total project ==========================
export const GET_TOTAL_PROJECT = "GET_TOTAL_PROJECT";
export const GET_TOTAL_PROJECT_SUCCSESS = "GET_TOTAL_PROJECT_SUCCSESS";
export const GET_TOTAL_PROJECT_FAIELD = "GET_TOTAL_PROJECT_FAIELD";
//================= send demo request ==================

export const SEND_DEMO_REQUEST = "SEND_DEMO_REQUEST";
export const SEND_DEMO_SUCCSESS = "SEND_DEMO_SUCCSESS";
export const SEND_DEMO_FAIELD = "SEND_DEMO_FAIELD";
//================ alart mail ====================
export const ALERT_MAIL_REQUEST = "ALERT_MAIL_REQUEST";
export const ALERT_MAIL_SUCCSESS = "ALERT_MAIL_SUCCSESS";
export const ALERT_MAIL_FAIELD = "ALERT_MAIL_FAIELD";

//================ alart mail ====================
export const NOTIFY_MAIL_REQUEST = "NOTIFY_MAIL_REQUEST";
export const NOTIFY_MAIL_SUCCSESS = "NOTIFY_MAIL_SUCCSESS";
export const NOTIFY_MAIL_FAIELD = "NOTIFY_MAIL_FAIELD";
