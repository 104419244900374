import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import CustomInput from "../../../../../components/Common/InputComponent/CustomInput";
import TextError from "../../../../../components/Common/InputComponent/TextError";
import { post } from "../../../../../helpers/api_helper";
import { clearData } from "../../../../../Redux/Lafarge/actions";

const initialValues = {
  questions: [
    {
      question:
        "1.Identify 3 concepts that you learned as a participant of the simulation",
      answer: "",
      questionformat: "textarea",
    },
    {
      question: "2.What aspects of the simulation were least helpful to you?",
      answer: "",
      questionformat: "textarea",
    },
    {
      question:
        "3.What would you do differently as a participant of the simulation?",
      answer: "",
      questionformat: "textarea",
    },
    {
      question:
        "4.Please provide any suggestions that might improve future simulation experiences.",
      answer: "",
      questionformat: "textarea",
    },
    {
      question:
        "5.Do you feel the facilitator of the debriefing session was helpful to your learning experience? Please provide feedback and examples",
      answer: "",
      questionformat: "textarea",
    },
  ],
};

const StepThree = ({ history }) => {
  const dispatch = useDispatch();
  const [values, setValues] = useState(null);
  const { stepThree, stepOne, stepTwo } = useSelector((state) => ({
    step: state.lafarge.step,
    stepThree: state.lafarge.stepThree,
    stepOne: state.lafarge.stepOne,
    stepTwo: state.lafarge.stepTwo,
  }));

  useEffect(() => {
    if (stepThree?.length) {
      const oldData = { ...values };
      oldData.questions = stepThree;
      setValues(oldData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepThree]);

  const onSubmit = async (values, { setSubmitting }) => {
    const data = {
      ...stepOne,
      feedback: [...stepTwo, ...values?.questions],
    };
    // console.log(`onSubmit ~ values`, data);
    try {
      const postData = await post("/laparge/new-survey", data);
      if (postData) {
        dispatch(clearData());

        history.push("/feedback/success");
      }
      setSubmitting(false);
    } catch (error) {
      console.log(error);
      setSubmitting(false);
    }
  };

  return (
    <React.Fragment>
      <div className="lafarge-step-three-form-main-wrap">
        <Container>
          <Row>
            <Col md="10" className="offset-md-1">
              <Formik
                initialValues={values || initialValues}
                // validationSchema={stepTwoSchema}
                onSubmit={onSubmit}
                enableReinitialize={true}
              >
                {({ values, errors, isSubmitting }) => (
                  <Form className="mt-4">
                    {/* {console.log("values", values, errors)} */}
                    <FieldArray
                      name="questions"
                      render={() => (
                        <Row>
                          {values?.questions?.map((value, index) => (
                            <Col md="12 mb-4" key={index}>
                              <Field
                                name={`questions.${index}.answer`}
                                label={value?.question}
                                type="textarea"
                                rows="6"
                                component={CustomInput}
                              />
                              <ErrorMessage
                                name={`questions.${index}.answer`}
                                component={TextError}
                              />
                            </Col>
                          ))}
                        </Row>
                      )}
                    />
                    <div className="mt-4 d-flex justify-content-center">
                      <button
                        className="btn lafarge-first-stap-button"
                        type="submit"
                        style={{ padding: "10px 50px" }}
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? "Submitting..." : "Submit"}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(StepThree);
