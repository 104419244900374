import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import EsQFooter from "../../SEP/Components/Pages/FeedBack/EsQFooter";
import LafargeHeader from "../../SEP/Components/Pages/Lafarge/LafargeHeader";
import CardComponent from "../../components/Common/CardComponent";
import successImage from "./successIcon.png";

export default function ElevateFeedbackSuccess() {
  return (
    <div>
      <LafargeHeader />
      <div className="lafarge-form-main-wrap">
        <Row className="">
          <Col md="7" className="mx-auto ">
            <CardComponent className={"lafarge-inner-form-wrap"}>
              <Col md="6" className="offset-md-3">
                <div className="lafarge-success-inner-wrap">
                  <img src={successImage} alt="" />
                  <h3>Successfully Submitted</h3>
                  <p>Thank you for your feedback.</p>
                  <Link to="/" className="btn lafarge-first-stap-button">
                    Explore
                  </Link>
                </div>
              </Col>
            </CardComponent>
          </Col>
        </Row>
      </div>
      <EsQFooter />
    </div>
  );
}
