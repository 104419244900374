import { call, put, takeEvery } from "redux-saga/effects";
import { postData } from "../../Dashboard/helpers/api_helper";

import { getRequestListFail, getRequestListSuccess } from "./action";
import { GET_REQUEST_LIST } from "./actionTypes";

function* fetchProjectListData({ payload: { authtoken, body } }) {
  console.log(body);
  try {
    const url = `/users/download-demo-request`;

    const response = yield call(postData, url, body, authtoken);

    yield put(getRequestListSuccess(response));
  } catch (error) {
    yield put(getRequestListFail(error));
  }
}

function* requestListSaga() {
  yield takeEvery(GET_REQUEST_LIST, fetchProjectListData);
}

export default requestListSaga;
