import { lazy, Suspense } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "slick-carousel/slick/slick-theme.css";
import "./App.css";
import "./assets/css/about.css";
import "./assets/css/ww-responsive.css";
import logoIcon from "./assets/image/maac-logo-icon.svg";
import MoveToTop from "./Common/MoveToTop";
import AuthLayout from "./Dashboard/Layout/AuthLayout";
import AuthMiddleware from "./Dashboard/Routes/Middleware/AuthMiddleware";
import { openRoute, protectedRoute } from "./Dashboard/Routes/Routes";
import "./loader.scss";
import DemoReqContact from "./page/DemoReqContact/DemoReqContact";
import Elevate from "./page/Elevate/Elevate.js";
import ElevateFeedbackSuccess from "./page/Elevate/ElevateFeedbackSuccess.js";
import ExcelDownloadBtn from "./page/ExcelDownloadBtn/ExcelDownloadBtn";
import RaffleDraw from "./page/RaffelDraw/RaffleDraw";
import RaffleDrawv2 from "./page/RaffelDraw/RaffleDrawv2";
import XamProForm from "./page/XamProForm/XamProForm";
import CampusActivation from "./SEP/Components/Pages/CampusActivation/CampusActivation";
import FeedbackSuccessFull from "./SEP/Components/Pages/CampusActivation/FeedbackSuccess";
import FeedBack from "./SEP/Components/Pages/FeedBack/FeedBack";
import FeedbackSuccess from "./SEP/Components/Pages/FeedBack/FeedbackSuccess";
import Lafarge2 from "./SEP/Components/Pages/Lafarge2/Lafarge2";
// import SepLafarge from "./SEP/Components/Pages/Sep_Lafarge/Sep_Lafarge";

const SepLafarge = lazy(() =>
  import("./SEP/Components/Pages/Sep_Lafarge/Sep_Lafarge")
);
const Products4 = lazy(() => import("./page/Fieldx/products/Products4"));

const EsQuareHome = lazy(() => import("./page/Ghorbari/EsQuare/EsQuareHome"));

const Ghorbari = lazy(() => import("./page/Ghorbari/products/Ghorbari"));

const Products3 = lazy(() => import("./page/ilsx/products/Products3"));

const InsightsDetails = lazy(() =>
  import("./page/InsightsDestails/InsightsDetails")
);

const InsightsDetails10 = lazy(() =>
  import("./page/InsightsDestails10/InsightsDetails10")
);

const InsightsDetails12 = lazy(() =>
  import("./page/InsightsDestails12/InsightsDetails12")
);

const InsightsDetails3 = lazy(() =>
  import("./page/InsightsDestails3/InsightsDetails3")
);

const InsightsDetails4 = lazy(() =>
  import("./page/InsightsDestails4/InsightsDetails4")
);
// ============================

const InsightsDetails5 = lazy(() =>
  import("./page/InsightsDestails5/InsightsDetails5")
);

const InsightsDetails6 = lazy(() =>
  import("./page/InsightsDestails6/InsightsDetails6")
);

const InsightsDetails7 = lazy(() =>
  import("./page/InsightsDestails7/InsightsDetails7")
);

const InsightsDetails8 = lazy(() =>
  import("./page/InsightsDestails8/InsightsDetails8")
);

const InsightsDetails9 = lazy(() =>
  import("./page/InsightsDestails9/InsightsDetails9")
);

const InsightsDetails2 = lazy(() =>
  import("./page/InsightsDestails_2/InsightsDetails2")
);

const BlackBelt = lazy(() =>
  import("./page/InsightsFilterData/BlackBelt/BlackBelt")
);

const BuisnessSimulation = lazy(() =>
  import("./page/InsightsFilterData/BuisnessSimulation/BuisnessSimulation")
);

const DataAnalytics = lazy(() =>
  import("./page/InsightsFilterData/DataAnalytics/DataAnalytics")
);

const DistributionSolution = lazy(() =>
  import("./page/InsightsFilterData/DistributionSolution/DistributionSolution")
);

const EdTech = lazy(() => import("./page/InsightsFilterData/EdTech/EdTech"));

const ESquare = lazy(() => import("./page/InsightsFilterData/ESquare/ESquare"));

const MarketingSales = lazy(() =>
  import("./page/InsightsFilterData/MarketingSales/MarketingSales")
);

const PeopleCulture = lazy(() =>
  import("./page/InsightsFilterData/PeopleCulture/PeopleCulture")
);

const StrategyCulture = lazy(() =>
  import("./page/InsightsFilterData/StrategyCulture/StrategyCulture")
);

const TalentManagenent = lazy(() =>
  import("./page/InsightsFilterData/TalentManagenent/TalentManagenent")
);

const TradeMarket = lazy(() =>
  import("./page/InsightsFilterData/TradeMarket/TradeMarket")
);

const Transformation = lazy(() =>
  import("./page/InsightsFilterData/Transformation/Transformation")
);

const MeDX = lazy(() => import("./page/MeDX/products/MeDX"));

const MerpX = lazy(() => import("./page/MerpX/products/MerpX"));

const NotFound = lazy(() => import("./page/NotFound/NotFound"));

const PmX = lazy(() => import("./page/Pmx/products/PmX"));

const ProductPage = lazy(() => import("./page/productPage/ProductPage"));

const Products6 = lazy(() => import("./page/retailx/products/Products6"));

const Products5 = lazy(() => import("./page/salesx/products/Products5"));

const Products2 = lazy(() => import("./page/somriddhi/products/Products2"));

const Team = lazy(() => import("./page/Team/Team"));

const Xampro = lazy(() => import("./page/Xampro/products/Xampro"));

const Xelerator = lazy(() => import("./page/Xelerator/products/Xelerator"));

const YourNeed = lazy(() => import("./page/YourNeed/YourNeed"));
const Home = lazy(() => import("./components/Home/Home"));
const MaacCommitment = lazy(() => import("./MaacGift/Home/MaacCommitment"));
const MaacGIftJoinUs = lazy(() => import("./MaacGift/Home/MaacGIftJoinUs"));
const MaacGIftLogin = lazy(() => import("./MaacGift/Home/MaacGIftLogin"));
const MaacGIft = lazy(() => import("./MaacGift/Home/MaacGIftProduct"));
const MaacGIftProduct = lazy(() => import("./MaacGift/Home/MaacGIftProduct"));
const MaacGIftThankYou = lazy(() => import("./MaacGift/Home/MaacGIftThankYou"));
const AboutUs = lazy(() => import("./page/aboutUs/AboutUs"));
const Products1 = lazy(() => import("./page/Calibrax/products/Products1"));
const CampaignX = lazy(() => import("./page/CampaignX/products/CampaignX"));
const Career = lazy(() => import("./page/Career/Career"));
const CareerDetails = lazy(() =>
  import("./page/Career/CareerDetails/CareerDetails")
);
const ClientsReview = lazy(() => import("./page/Clients/ClientsReview"));
const Contact = lazy(() => import("./page/Contact Us/Contact"));
const CourseWare = lazy(() => import("./page/CourseWare/products/CourseWare"));
const Encode = lazy(() => import("./page/Encode/products/Encode"));
const InsightsHome = lazy(() => import("./page/Insights/InsightsHome"));

const Lafarge = lazy(() => import("./SEP/Components/Pages/Lafarge/Lafarge"));

const LafargeSuccess = lazy(() =>
  import("./SEP/Components/Pages/Lafarge/LafargeSuccess")
);

const SalesEffect2 = lazy(() =>
  import("./SEP/Components/Pages/SalesEffect2/SalesEffect2")
);
const SepU = lazy(() => import("./SEP/Components/Pages/Sep_U/Sep_U"));

const Tas = lazy(() => import("./SEP/Components/Pages/TAS/Tas"));
const TASSomriddhi = lazy(() =>
  import("./SEP/Components/Pages/TASSomriddhi/TASSomriddhi.js")
);

function App() {
  return (
    <Router>
      <Suspense
        fallback={
          <div className="maac-website-loader ">
            <div className="zoom-in-zoom-out">
              <svg
                width="41"
                height="45"
                viewBox="0 0 41 45"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M23.8658 3.81069C23.8658 6.80787 20.6953 9.01293 17.8246 6.61519C15.618 3.46816 17.6318 0 20.3739 0C22.302 0 23.8658 1.71267 23.8658 3.81069Z"
                  fill="#EB2027"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M23.8654 41.1893C23.8654 43.3087 22.3015 45 20.3735 45C18.4454 45 16.8815 43.2873 16.8815 41.1893C16.8815 39.0699 18.4454 37.3786 20.3735 37.3786C22.3015 37.3572 23.8654 39.0699 23.8654 41.1893Z"
                  fill="#EB2027"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15.4244 31.5127C15.4244 33.6321 13.8606 35.3234 11.9325 35.3234C10.0045 35.3234 8.44059 33.6107 8.44059 31.5127C8.44059 29.3933 10.0045 27.702 11.9325 27.702C15.2745 27.702 16.8812 27.0597 16.8812 22.6068C16.8812 18.3679 15.553 16.8694 11.9325 16.8694C7.86218 16.8694 6.98384 18.9032 6.98384 22.6068C6.98384 24.7262 5.41998 26.4175 3.49192 26.4175C1.56387 26.4175 0 24.7048 0 22.6068C0 20.5088 1.56387 18.7961 3.49192 18.7961C7.11238 18.7961 8.44059 17.2975 8.44059 13.0587C8.44059 10.9392 10.0045 9.22656 11.9325 9.22656C13.8606 9.22656 15.4244 10.9392 15.4244 13.0373C15.4244 16.7195 16.3028 18.7747 20.3731 18.7747C23.9936 18.7747 25.3218 17.2761 25.3218 13.0373C25.3218 10.9178 26.8857 9.22656 28.8137 9.22656C30.7418 9.22656 32.3056 10.9392 32.3056 13.0373C32.3056 17.2761 33.6338 18.7747 37.2543 18.7747C39.1824 18.7747 40.7462 20.4874 40.7462 22.5854C40.7462 24.6834 39.1824 26.3961 37.2543 26.3961C35.3262 26.3961 33.7624 24.6834 33.7624 22.5854C33.7624 18.9031 32.884 16.8479 28.8137 16.8479C24.7434 16.8479 23.865 18.8817 23.865 22.5854C23.865 27.0383 25.4503 27.6806 28.8137 27.6806C30.7418 27.6806 32.3056 29.3933 32.3056 31.4913C32.3056 33.6107 30.7418 35.302 28.8137 35.302C26.8857 35.302 25.3218 33.5893 25.3218 31.4913C25.3218 27.4879 24.2078 26.3961 20.3731 26.3961C16.5384 26.3961 15.4244 27.5093 15.4244 31.5127Z"
                  fill="#1B3F73"
                />
              </svg>
            </div>
          </div>
        }
      >
        <div className="all-page-logo">
          <img src={logoIcon} alt="" />
        </div>
        <div className="social-media-link-wrap">
          <a
            href="https://www.facebook.com/MAAC.Bangladesh"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-facebook"></i>
          </a>
          <a
            href="https://twitter.com/MAAC_Solutions"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-twitter"></i>
          </a>

          <a
            href="https://www.linkedin.com/company/market-access-analytics-and-consulting-maac"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-linkedin"></i>
          </a>
        </div>
        <MoveToTop />
        {/* <MyDataChart /> */}

        <Switch>
          {openRoute.map((route, idx) => (
            <Route
              exact
              path={route.path}
              component={route.component}
              key={idx}
            />
          ))}
          {protectedRoute.map((route, idx) => (
            <AuthMiddleware
              exact={route.exact}
              path={route.path}
              key={idx}
              roles={route.roles}
              component={route.component}
              layout={AuthLayout}
              isAuthProtected
            />
          ))}
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/calibrax">
            <Products1 />
          </Route>
          <Route exact path="/ilsx">
            <Products3 />
          </Route>
          <Route exact path="/shomriddhi">
            <Products2 />
          </Route>
          <Route exact path="/fieldx">
            <Products4 />
          </Route>
          <Route exact path="/salesx">
            <Products5 />
          </Route>
          <Route exact path="/retailx">
            <Products6 />
          </Route>
          <Route exact path="/course-ware">
            <CourseWare />
          </Route>
          <Route exact path="/xampro">
            <Xampro />
          </Route>
          <Route exact path="/merpx">
            <MerpX />
          </Route>
          <Route exact path="/campaignx">
            <CampaignX />
          </Route>
          <Route exact path="/ghorbari">
            <Ghorbari />
          </Route>
          <Route exact path="/e-square">
            <EsQuareHome />
          </Route>
          <Route exact path="/encode">
            <Encode />
          </Route>
          <Route exact path="/medx">
            <MeDX />
          </Route>
          {/* <Route exact path="/mapx">
          <MapX />
        </Route> */}
          <Route exact path="/pmx">
            <PmX />
          </Route>
          <Route exact path="/xelerator">
            <Xelerator />
          </Route>
          <Route exact path="/product">
            <ProductPage />
          </Route>
          <Route exact path="/aboutus">
            <AboutUs />
          </Route>
          {/* <Route exact path="/service">
          <Service />
        </Route> */}
          <Route exact path="/your-need">
            <YourNeed />
          </Route>
          <Route exact path="/team">
            <Team />
          </Route>
          <Route exact path="/contact">
            <Contact />
          </Route>
          <Route exact path="/career">
            <Career />
          </Route>
          <Route exact path="/career-details">
            <CareerDetails />
          </Route>
          {/* <Route exact path="/clients">
          <Clients />
        </Route> */}
          <Route exact path="/client-review">
            <ClientsReview />
          </Route>
          <Route exact path="/insights">
            <InsightsHome />
          </Route>
          <Route exact path="/insight-post">
            <InsightsDetails />
          </Route>
          <Route exact path="/insight-post2">
            <InsightsDetails2 />
          </Route>
          <Route exact path="/insight-post-3">
            <InsightsDetails3 />
          </Route>
          <Route exact path="/insight-post-4">
            <InsightsDetails4 />
          </Route>
          <Route exact path="/insight-post-5">
            <InsightsDetails5 />
          </Route>
          <Route exact path="/insight-post-6">
            <InsightsDetails6 />
          </Route>
          <Route exact path="/insight-post-7">
            <InsightsDetails7 />
          </Route>
          <Route exact path="/insight-post-8">
            <InsightsDetails8 />
          </Route>
          <Route exact path="/insight-post-9">
            <InsightsDetails9 />
          </Route>
          <Route exact path="/insight-post-10">
            <InsightsDetails10 />
          </Route>
          <Route exact path="/insight-post-12">
            <InsightsDetails12 />
          </Route>
          <Route exact path="/maacgift-login">
            <MaacGIftLogin />
          </Route>
          <Route exact path="/maacgift/join-us">
            <MaacGIftJoinUs />
          </Route>
          <Route exact path="/maacgift/porduct-list">
            <MaacGIftProduct />
          </Route>
          <Route exact path="/maacgift/commitment">
            <MaacCommitment />
          </Route>
          <Route exact path="/maacgift/thankyou">
            <MaacGIftThankYou />
          </Route>
          <Route exact path="/maacGift">
            <MaacGIft />
          </Route>
          <Route exact path="/data-analytics">
            <DataAnalytics />
          </Route>
          <Route exact path="/marketing-sales">
            <MarketingSales />
          </Route>
          <Route exact path="/e-square">
            <ESquare />
          </Route>
          <Route exact path="/e-square-tm">
            <ESquare />
          </Route>
          <Route exact path="/talent-management">
            <TalentManagenent />
          </Route>
          <Route exact path="/trade-marketing">
            <TradeMarket />
          </Route>
          <Route exact path="/distribution-solution">
            <DistributionSolution />
          </Route>
          <Route exact path="/people-culture">
            <PeopleCulture />
          </Route>
          <Route exact path="/strategy-insights">
            <StrategyCulture />
          </Route>
          <Route exact path="/black-belt">
            <BlackBelt />
          </Route>
          <Route exact path="/transformation">
            <Transformation />
          </Route>
          <Route exact path="/business-simulation">
            <BuisnessSimulation />
          </Route>
          <Route exact path="/ed-tech">
            <EdTech />
          </Route>
          <Route exact path="/sep">
            <SalesEffect2 />
          </Route>
          <Route exact path="/sep_u">
            <SepU />
          </Route>
          <Route exact path="/sep_lafarge">
            <SepLafarge />
          </Route>
          <Route exact path="/tas">
            <Tas />
          </Route>{" "}
          <Route exact path="/ccb">
            <TASSomriddhi />
          </Route>
          <Route exact path="/lafarge">
            <Lafarge />
          </Route>{" "}
          <Route exact path="/newzealand-dairy">
            <Lafarge />
          </Route>
          <Route exact path="/newzealand-dairy/success">
            <FeedbackSuccess />
          </Route>
          <Route exact path="/feedback-elevate">
            <Elevate />
          </Route>{" "}
          <Route exact path="/feedback-elevate/success">
            <ElevateFeedbackSuccess />
          </Route>{" "}
          <Route exact path="/feedback">
            <FeedBack />
          </Route>
          <Route exact path="/campus-activation">
            <CampusActivation />
          </Route>
          <Route exact path="/feedback_ASE">
            <Lafarge2 />
          </Route>
          <Route exact path="/lafarge/success">
            <FeedbackSuccess />
          </Route>
          <Route exact path="/exampro/success">
            <FeedbackSuccessFull />
          </Route>
          <Route exact path="/feedback/success">
            <FeedbackSuccess />
          </Route>
          <Route exact path="/feedback/download_button">
            <ExcelDownloadBtn />
          </Route>
          <Route exact path="/xampro-form-submit">
            <XamProForm />
          </Route>
          <Route exact path="/course-demo-request">
            <DemoReqContact />
          </Route>
          <Route exact path="/growbeyond-maac_ana7_jw_ygt_2023">
            <RaffleDraw />
          </Route>{" "}
          <Route exact path="/commercial-meet-2024-raffledraw">
            <RaffleDrawv2 />
          </Route>
          {/* <Route exact path="/check-deploy">
            <h2>check</h2>
          </Route> */}
          <Route exact path="*">
            <NotFound />
          </Route>
        </Switch>
      </Suspense>
      <ToastContainer />
    </Router>
  );
}

export default App;
