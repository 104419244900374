import {
  ALERT_MAIL_REQUEST,
  GET_PROJECT_FAIELD,
  GET_PROJECT_SUCCSESS,
  GET_TOTAL_PROJECT_FAIELD,
  GET_TOTAL_PROJECT_SUCCSESS,
  NOTIFY_MAIL_REQUEST,
  SEND_DEMO_REQUEST,
  STORE_DASHBOARD_DATA,
} from "./actionTypes";
const INIT_STATE = {
  project: [],
  projectCount: [],
  projectCountLoading: false,
  appLink: "",
  subject: "",
  startDateRange: "2022-09-18",
  endDateRange: "2024-09-18",
  projectLoading: false,
  demoRequestLoading: false,
  alertMailLoading: false,
  notifyMailLoading: false,
};
const DashboardReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SEND_DEMO_REQUEST:
      return {
        ...state,

        demoRequestLoading: true,
      };
    case ALERT_MAIL_REQUEST:
      return {
        ...state,

        alertMailLoading: true,
      };
    case NOTIFY_MAIL_REQUEST:
      return {
        ...state,

        notifyMailLoading: true,
      };
    case GET_PROJECT_SUCCSESS:
      return {
        ...state,
        project: action.payload,
        projectLoading: false,
      };
    case GET_PROJECT_FAIELD:
      return {
        ...state,
        error: action.payload,
        projectLoading: false,
      };
    case STORE_DASHBOARD_DATA:
      return {
        ...state,
        [action.payload.name]: action.payload.data,
      };
    case GET_TOTAL_PROJECT_SUCCSESS:
      return {
        ...state,
        projectCount: action.payload,
        projectCountLoading: false,
      };
    case GET_TOTAL_PROJECT_FAIELD:
      return {
        ...state,
        error: action.payload,
        projectCountLoading: false,
      };

    default:
      return state;
  }
};
export default DashboardReducer;
