import moment from "moment";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { storeDashboardData } from "../../../store/Dashboard/action";

const CustomCard = () => {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );

  const handleStartDate = (e) => {
    setStartDate(e.target.value);
    dispatch(
      storeDashboardData(
        "startDateRange",
        moment(e.target.value).format("YYYY-MM-DD")
      )
    );
  };
  const handleEndDate = (e) => {
    setEndDate(e.target.value);
    dispatch(
      storeDashboardData(
        "endDateRange",
        moment(e.target.value).format("YYYY-MM-DD")
      )
    );
  };
  const handleChange = (e) => {
    const newDataValue = e.target.value;
    dispatch(storeDashboardData("subject", newDataValue));
    // console.log(newDataValue.toLowerCase());
  };
  return (
    <div>
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-lg-6 date_search_area">
              <Form className="form-horizontal-form-wrap">
                <Form.Group className="form-data-filtering custom-bottom-margin">
                  <Form.Label>From Date</Form.Label>
                  <Form.Control
                    type="date"
                    value={startDate}
                    onChange={handleStartDate}
                  />
                </Form.Group>
                <Form.Group className="form-data-filtering">
                  <Form.Label>To Date</Form.Label>
                  <Form.Control
                    type="date"
                    value={endDate}
                    onChange={handleEndDate}
                  />
                </Form.Group>
              </Form>{" "}
            </div>
            <div className="col-lg-6 date_search_area">
              <Form className="form-horizontal-form-wrap">
                <Form.Label>Filter by product</Form.Label>
                <Form.Select onChange={handleChange}>
                  <option value="">All</option>
                  <option value="FieldX">FieldX</option>
                  <option value="SalesX">SalesX</option>
                  <option value="courseWare">courseWare</option>
                  <option value="CalibraX">CalibraX</option>
                </Form.Select>
              </Form>{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomCard;
