import {
  ADD_CATEGORIES_LOADED,
  DELETE_CATEGORIES_LOADED,
  GET_CATEGORIES,
  GET_CATEGORIES_ERROR,
  GET_CATEGORIES_LOADED,
} from "./actionTypes";

const initialState = {
  loading: false,
  categories: {},
  error: "",
};

const categoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CATEGORIES_LOADED:
      return {
        ...state,
        loading: true,
      };
    case GET_CATEGORIES:
      return {
        ...state,
        loading: false,
        error: "",
        categories: action.payload,
      };
    case GET_CATEGORIES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ADD_CATEGORIES_LOADED:
      return {
        ...state,
      };
    case DELETE_CATEGORIES_LOADED:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default categoryReducer;
