import React from "react";
import { Container, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import lafargeLogo from "./img/lafarge.svg";
import maac from "./img/logo-main.svg";
import newZealandDairy from "./img/logo.png";
const LafargeHeader = ({ checkPath }) => {
  return (
    <header className="header-main-area-wrap sep-new-page lafarge-page-header">
      <Navbar expand="lg">
        <Container>
          <div className="lafarge-header-main-wrap">
            <Navbar.Brand>
              <Link to="/" className="header-sep-left-area tas">
                <img src={maac} alt="" />
                {/* <span></span> */}
                {/* <img src={logo} alt="" /> */}
              </Link>
            </Navbar.Brand>
            <div className="">
              <img
                src={
                  checkPath === "/newzealand-dairy/success"
                    ? newZealandDairy
                    : lafargeLogo
                }
                style={{ height: "60px" }}
                alt=""
              />
            </div>
          </div>
        </Container>
      </Navbar>
    </header>
  );
};

export default LafargeHeader;
