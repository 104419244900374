import React from "react";
import EventDetailsHeader from "../../Dashboard/Components/Others/EventDetailsHeader";
import MobileFooter from "../../Dashboard/Components/Others/MobileFooter";
import BkashIcon from "../../assets/image/others/bkash-logo.png";
import useCustomFavicon from "../../hooks/useCustomFavicon";
import { TabTitle } from "../../utils/GeneralFunctions";
import MobileContainer from "./MobileContainer";

export default function EventDetailsPage() {
  TabTitle("Itinerary Details");
  useCustomFavicon(BkashIcon);

  return (
    <MobileContainer>
      <EventDetailsHeader />
      <div className="position-absolute bottom-0 w-100">
        <MobileFooter />
      </div>
    </MobileContainer>
  );
}
