import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import BreadcrumbDashboard from "../../../Breadcrumb/BreadcrumbDashboard";
import DashboardTitle from "../../components/DashboardTitle";
import EditIcon from "../../images/edit.svg";
import ViewIcon from "../../images/eye.svg";
import MenuIcon from "../../images/menu.svg";

export default function LandingPage() {
  const history = useHistory();

  return (
    <section>
      <Container fluid>
        <BreadcrumbDashboard leftTitle="Landing-Page" />
        <DashboardTitle
          title="Landing Page"
          description="You can change your landing page design as you want"
        />
        <Row style={{ minHeight: "500px" }}>
          {landingPageContent?.map((item, idx) => (
            <Col md={3} key={idx}>
              <Card className="py-2">
                <div className="p-4 d-flex justify-content-between align-items-center flex-row gap-3">
                  <div>
                    <h5 className="mb-3">{item?.name}</h5>
                    <div className="d-flex align-items-center flex-row gap-3">
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => history.push(item?.editUrl)}
                      >
                        <img src={EditIcon} alt="" style={{ width: "14px" }} />
                        <span className="ms-1" style={{ fontSize: "12px" }}>
                          Edit
                        </span>
                      </div>
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => history.push(item?.viewUrl)}
                      >
                        <img src={ViewIcon} alt="" style={{ width: "14px" }} />
                        <span className="ms-1" style={{ fontSize: "12px" }}>
                          View
                        </span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <img src={MenuIcon} alt="" style={{ width: "16px" }} />
                  </div>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
}

const landingPageContent = [
  // { id: 1, name: "Centro Logo" },
  // { id: 2, name: "Main Menu" },
  {
    id: 3,
    name: "Hero Section",
    editUrl: "/centro/landing-page/edit-hero/Hero",
    viewUrl: "/centro/landing-page/view-hero/Hero",
  },
  // { id: 4, name: "Our Products" },
  {
    id: 5,
    name: "About",
    editUrl: "/centro/landing-page/edit-hero/About",
    viewUrl: "/centro/landing-page/view-hero/About",
  },
  // { id: 5, name: "Working process" },
  // { id: 6, name: "Meet our people" },
  // { id: 7, name: "Work With Us" },
  // { id: 8, name: "Get In Touch" },
  // { id: 9, name: "Footer" },
];
