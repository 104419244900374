import React from "react";

export default function LocationIcon() {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M17.9397 16.746C21.2201 13.4641 21.2201 8.14324 17.9397 4.8614C14.6593 1.57957 9.34071 1.57957 6.0603 4.8614C2.7799 8.14324 2.7799 13.4641 6.0603 16.746L7.88553 18.5446L10.3369 20.9267L10.4963 21.068C11.426 21.8216 12.7875 21.7746 13.6633 20.927L16.5868 18.083L17.9397 16.746ZM14.5757 10.0243L12.6 8.04855V13.8C12.6 14.1314 12.3314 14.4 12 14.4C11.6686 14.4 11.4 14.1314 11.4 13.8L11.4 8.04855L9.42426 10.0243C9.18995 10.2586 8.81005 10.2586 8.57573 10.0243C8.34142 9.78997 8.34142 9.41007 8.57573 9.17576L11.5757 6.17576C11.6883 6.06324 11.8409 6.00002 12 6.00002C12.1591 6.00002 12.3117 6.06324 12.4243 6.17576L15.4243 9.17576C15.6586 9.41008 15.6586 9.78997 15.4243 10.0243C15.1899 10.2586 14.81 10.2586 14.5757 10.0243Z"
          fill="#E2136E"
        />
      </svg>
    </div>
  );
}
