import { Link } from "react-router-dom";
import "../assets/css/footer.css";
import logo from "../assets/image/logo-main.svg";
import logo2 from "./img/390249_thumb 1.png";
import basis from "./img/basis.svg";
import logo3 from "./img/CBS.png";
import hei from "./img/hei.png";
const Footer = () => {
  return (
    <footer className="footer-main-area">
      <div className="footer-top">
        <div className="container">
          <div className="footer-top-inner">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12">
                <div className="footer-top-item-left">
                  <Link to="/">
                    <img src={logo} alt="" />
                  </Link>
                  <div className="footer-left-text-p">
                    <p>DATA | DECISION | DISRUPTION</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 footer-item-hide">
                {" "}
                <div className="footer-top-right-single-item pl-2">
                  <ul className="footer-nav">
                    <li>
                      <Link to="/aboutus">Why Us</Link>
                    </li>

                    <li>
                      <Link to="/your-need">Your Needs </Link>
                    </li>
                    <li>
                      <Link to="/contact">Connect </Link>
                    </li>

                    <li>
                      <Link to="/team">Team</Link>
                    </li>
                    {/* <li>
                      <a href="!#">News</a>
                    </li> */}
                  </ul>
                  <ul className="footer-2nd-link">
                    {/* <li>
                      <Link to="/team">Team</Link>
                    </li> */}
                    {/* <li>
                      <Link to="/blog">Blogs</Link>
                    </li> */}

                    {/* <li>
                      <Link to="/clients">Clients</Link>
                    </li> */}
                    {/* <li>
                      <Link to="/client-review">Testimonial</Link>
                    </li> */}
                    {/* <li>
                      <a href="!#">Privacy Policy</a>
                    </li> */}
                    {/* <li>
                      <a href="!#">Terms of use</a>
                    </li> */}
                  </ul>
                  {/* <div className="footer-top-right-title">
                    <h6>Resourses</h6>
                  </div> */}
                  <div className="service-items">
                    <ul>
                      {/* <li>
                        <Link to="/clients">Clients</Link>
                      </li>
                      <li>
                        <Link to="/client-review">Testimonial</Link>
                      </li>
                      <li>
                        <a href="!#">Privacy Policy</a>
                      </li>
                      <li>
                        <a href="!#">Terms of use</a>
                      </li> */}

                      {/* <li>
                        <a href="!#">Information Technology</a>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                {" "}
                <div className="footer-top-right-single-item  pl-3">
                  <div className="footer-top-right-title">
                    <h6>
                      <i>Member Of</i>
                    </h6>
                  </div>
                  <div className="service-items">
                    <img src={basis} alt="" />
                    <img src={hei} alt="" />
                    <img src={logo2} alt="" />
                    <img src={logo3} alt="" />
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-4 col-md-6 footer-item-hide">
                <div className="footer-top-right-single-item">
                  <div className="footer-top-right-title">
                    <h6><i>Member Of</i></h6>
                  </div>
                  <div className="service-items">
                    <img src={basis} alt="" />
                   
                  </div>
                </div>
              </div> */}
            </div>
            {/* <div className="footer-top-item-left">
              <Link to="/">
                <img src={logo} alt="" />
              </Link>

              <p>
                MAAC Solution is your data-based business consultant. We provide
                expert solution designs for Retail (mSMEs) and Education fronts.
                Backed with AI, Machine Learning and Data-based control aspects,
                we are known for being distinctive in connecting, capturing and
                captivating the consumer base.
              </p>
            </div> */}
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="footer-bottom-inner">
            <div className="footer-bottom-left">
              <p>
                © {new Date().getFullYear()} <span>MAAC</span>
              </p>
            </div>
            <div className="footer-bottom-right">
              <a
                href="https://www.facebook.com/MAAC.Bangladesh"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-facebook"></i>
              </a>
              <a
                href="https://twitter.com/MAAC_Solutions"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-twitter"></i>
              </a>
              {/* <a href="!#">
                <i className="fab fa-instagram"></i>
              </a> */}
              <a
                href="https://www.linkedin.com/company/market-access-analytics-and-consulting-maac/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-linkedin"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
