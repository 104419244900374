import { useEffect } from "react";

export default function useCustomFavicon(icon) {
  useEffect(() => {
    const changeFavicon = () => {
      const link = document.querySelector("link[rel~='icon']");
      if (link !== undefined) {
        link.href = icon;
      }
    };

    changeFavicon();

    return () => {
      const link = document.querySelector("link[rel~='icon']");
      if (link !== undefined) {
        link.href = icon;
      }
    };
  }, [icon]);

  return null;
}
