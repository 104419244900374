import { Field, Form, Formik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PlaceholderImage from "../../../../assets/image/placeholder-image.jpg";
import CustomInput from "../../../../components/Common/InputComponent/CustomInput";
import {
  addHeroLoad,
  getHeroLoaded,
} from "../../../../Redux/Centro/Hero/action";
import Loader from "../../../Components/Common/Loader";
import DashboardTitle from "../../components/DashboardTitle";
import { createSectionSchema } from "../../schemas/createSectionSchema";

const initialValue = {
  title: "",
  details: "",
  file: "",
  files: {},
  imageUrl: "",
};

export default function EditHero({ view }) {
  const [values, setValues] = useState(null);
  const dispatch = useDispatch();
  const { type } = useParams();
  const { loading, hero } = useSelector((state) => ({
    loading: state?.hero?.loading,
    error: state?.hero?.error,
    hero: state?.hero?.hero?.datas,
  }));

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    const formData = new FormData();
    formData.append("photo", values.files);
    formData.append("title", values.title);
    formData.append("details", values.details);
    formData.append("type", type);

    dispatch(
      addHeroLoad({
        data: formData,
        payload: {
          setSubmitting,
          resetForm,
          toggle,
          id: hero?.[0]?._id || null,
          type,
        },
      })
    );
  };

  const getProducts = useCallback(
    () => dispatch(getHeroLoaded({ payload: { type } })),
    [dispatch, type]
  );

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  const toggle = (api) => {
    if (api) {
      getProducts();
    }
  };

  useEffect(() => {
    if (hero?.[0]?._id) {
      const obj = {};
      obj.title = hero?.[0]?.title || "";
      obj.details = hero?.[0]?.details || "";
      obj.imageUrl = hero?.[0]?.image || "";
      obj.file = "";
      obj.files = {};

      setValues(obj);
    }
  }, [hero]);

  return (
    <section className="add_product_section px-4 my-5 py-4">
      {type === "About" || type === "Hero" ? (
        <Container fluid>
          {/* <BreadcrumbDashboard leftTitle="Add Products" /> */}
          <DashboardTitle
            title={type === "About" ? "Edit About" : "Edit Hero Section"}
          />
          {loading ? (
            <div className="d-flex justify-content-center align-items-center py-5">
              <Loader />
            </div>
          ) : (
            <Row>
              <Col md={6}>
                <Card className="p-4">
                  <Formik
                    initialValues={values || initialValue}
                    validationSchema={createSectionSchema}
                    onSubmit={onSubmit}
                    enableReinitialize={true}
                  >
                    {({ values, errors, isSubmitting, setFieldValue }) => (
                      <Form>
                        <Row>
                          {type === "About" && (
                            <Col md="12 mb-2">
                              <Field
                                name="title"
                                label={`${type} Title`}
                                type="text"
                                component={CustomInput}
                                placeholder={`${type} Title`}
                                isRequired
                                disabled={view}
                              />
                            </Col>
                          )}
                          <Col md="12 mb-3">
                            <Field
                              name="file"
                              type="file"
                              label={`${type} Image`}
                              component={CustomInput}
                              placeholder="Type Details"
                              onChangeCallback={(data) => {
                                const file = data?.target?.files;
                                if (file?.length) {
                                  setFieldValue("files", file?.[0]);
                                }
                              }}
                              disabled={view}
                            />
                            {hero?.[0]?._id && (
                              <LazyLoadImage
                                style={{
                                  objectFit: "cover",
                                  width: "100px",
                                  minHeight: "100px",
                                  cursor: "pointer",
                                }}
                                // height="100px"
                                // className="features_image"
                                // loading="Loading..."
                                alt={""}
                                effect="blur"
                                placeholderSrc={PlaceholderImage}
                                src={
                                  values?.files.name
                                    ? URL.createObjectURL(values?.files)
                                    : values?.imageUrl
                                }
                              />
                            )}

                            {/* <img
                              src={
                                values?.files.name
                                  ? URL.createObjectURL(values?.files)
                                  : values?.imageUrl
                              }
                              style={{ width: "100px " }}
                              alt=""
                            /> */}
                          </Col>
                          {type === "About" && (
                            <Col md="12 mb-2">
                              <Field
                                name="details"
                                label={`${type} Details`}
                                rows={4}
                                type="textarea"
                                component={CustomInput}
                                placeholder="Type Details"
                                disabled={view}
                              />
                            </Col>
                          )}
                        </Row>

                        {!view && (
                          <div className="mt-4 d-flex justify-content-start gap-2">
                            <button
                              className="btn create-btn"
                              disabled={isSubmitting}
                              type="submit"
                            >
                              {isSubmitting ? "Submitting..." : "Publish"}{" "}
                            </button>
                          </div>
                        )}
                      </Form>
                    )}
                  </Formik>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      ) : (
        <Container>
          <center className="mt-5">Something went wrong</center>
        </Container>
      )}
    </section>
  );
}
